import axios from 'axios';
import {SERVER_URL} from '../../constant';
import api from '../../api/interceptor';

export const addQuestionAction = (uploadData, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/create_topic',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : uploadData,
    };
    
    api.post('/admin/game/', uploadData)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        err();
        console.log(error);
    });
}

export const editQuestionAction = (updateData, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/quiz_edit',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : updateData,
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getTopicsAction = (callback, err) => {
    api.post('admin/game/list')
    .then(function (res) {
        res.status === 200 ? callback(res.data.list)  : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const downloadTemplate = async (callback) => {
    axios.get(SERVER_URL + 'excel/download', {
        method: 'GET',
        responseType: 'blob', // important
        crossdomain: true,
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `standard.xlsx`);
        document.body.appendChild(link);
        link.click();
    });
}

export const getCategories = (callback, err) => {
    var config = {
        method: 'get',
        url: SERVER_URL + 'category/list',
        crossdomain: true,
        // headers: { 
        //     'x-access-token': loginInfo.accessToken
        // },
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data) : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const editGameAction = (updateData, callback, err) => {
    api.put('/admin/game/', updateData)
    .then(function (res) {
        res.status === 200 ? callback(res.data.message) : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}