import { Button, Modal, Input, Typography, Collapse, Table, Progress } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';

import ModalSearch from '../../components/dashboard_modal/ModalSearch';

import { teamRankingAction, getCompetitionList, satisticAction, exportExcelData, downloadStaticAction } from '../../components/actions/Game';
import { playerRankingAction, playerRankingByCompetitionAction } from '../../components/actions/Player';

import { formatAMPM } from '../../function';
import '../../scss/dashboard.scss';

const { Text, Title, Link } = Typography;
const { Search } = Input;
const { Panel } = Collapse;

export default function DashboardPage() {
  const navigate = useNavigate();
  const [modalStep, setModalStep] = useState('game');
  const [teamRank, setTeamRank] = useState([]);
  const [pendingCnt, setPendingCnt] = useState(0);
  const [startCnt, setStartCnt] = useState(0);
  const [completeCnt, setCompleteCnt] = useState(0);
  const [quizPer, setQuizPer] = useState(0);
  const [joinedPer, setJoinedPer] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [quizList, setQuizList] = useState([]);
  const [playerRank, setPlayerRank] = useState([]);
  const [mountState, setMountState] = useState(0);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [username, setUserName] = useState('');
  const [searchText, setSearchText] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedCompetition, setSelectedCompetition] = useState({});
  const [selectedGame, setSelectedGame] = useState({});
  const [pageTitle, setPageTitle] = useState('');
  const [pageSubTitle, setPageSubTitle] = useState('');

  useEffect(() => {
    if (selectedUser.title) {
      setPageTitle(selectedUser.title);
      setPageSubTitle('');
    }
    if (selectedCompetition.title) {
      setPageTitle(selectedCompetition.title);
    }
    if (selectedCompetition.game) {
      setPageSubTitle(selectedCompetition.game);
    }
    if (selectedGame.title) {
      setPageTitle(selectedGame.title);
      setPageSubTitle('');
    }
    setMountState(0);
    // setPageSubTitle('Juego');
  }, [selectedCompetition, selectedGame, selectedUser])

  useEffect(() => {
    if (!mountState) {
      // teamRankingAction(selectedCompetition.id ? selectedCompetition.id : -1, (data) => {
      //   // console.log('hello', data[0][0]);
      //   let teamRankData = data[0].map((item, index) => {
      //     return {
      //       key: index + 1,
      //       position: '#' + (index + 1),
      //       equipment: item.competition + " " + item.team,
      //       member: item.player,
      //       score: item.score,
      //       hit: (item.receivedQuizCnt ? item.correctAnswerCnt * 100 / item.receivedQuizCnt : null),
      //       challengeC: item.completed_cnt,
      //       challengeP: item.pending_cnt,
      //     }
      //   });
      //   setTeamRank(teamRankData);
      // });
      getCompetitionList({
          competitionId: selectedCompetition.id ? selectedCompetition.id : 0,
          gameId: selectedGame.id ? selectedGame.id : 0,
        }, (list) => {
          let pendingCnt = 0;
          let startCnt = 0;
          let completeCnt = 0;
          list.map((compete) => {
              if (new Date(compete.startDate) > new Date()) {
                pendingCnt += 1;
              }
              if (new Date(compete.startDate) <= new Date() && new Date(compete.endDate) >= new Date()) {
                startCnt += 1;
              }
              if (new Date(compete.endDate) > new Date()) {
                completeCnt += 1;
              }
          });
        });
    

      let idSearch = '';
      let typeSearch = '';

      console.log('====selectedCompetition', selectedCompetition);
      console.log('====selectedGame', selectedGame);

      if(selectedCompetition.id){
        idSearch=selectedCompetition.id;
        typeSearch='competition';
      }else if(selectedGame.id){
        idSearch = selectedGame.id
        typeSearch='game';
      }  
      
      satisticAction(
        { 
          id:idSearch,
          type:typeSearch
        }, 
        (data) => {
          setQuizPer(data.general.aciertos);
          setJoinedPer(data.general.participacion);
          setPendingCnt(data.general.desafios.pendientes);
          setStartCnt(data.general.desafios.lanzandos);
          setCompleteCnt(data.general.desafios.contestados);
          let totalCnt = 0;
          // data.porCategoria.map((category) => {
          //   totalCnt += parseInt(category.category_cnt);
          // });
          let categoriese = data.porCategoria.map((category, index) => {
            return {
              key: index + 1,
              category: category.categoria,
              total: category.total,
              hit: category.aciertos,
              manage: Math.round((category.aciertos/category.total)*100),
            }
          });
          setCategoryList(categoriese);

          let quizs = data.porPregunta.map((quiz, index) => {
            if (quiz.correctas) {
              return {
                key: index + 1,
                ask: quiz.pregunta,
                correct: `${Math.round((quiz.correctas/quiz.total)*100)}% (${quiz.correctas})`,
                incorrect: `${Math.round((quiz.incorrectas/quiz.total)*100)}% (${quiz.total-quiz.correctas})`,
                total: quiz.total,
              };
            } else {
              return {
                key: index + 1,
                ask: quiz.pregunta,
                correct: quiz.correctas,
                incorrect: quiz.incorrectas,
                total: quiz.total,
              };
            }
          });
          setQuizList(quizs);

          let players = data.ranking.jugadores.map((item, index) => {
            return {
              key: index + 1,
              position: item.puesto,
              name: item.name,
              equipment: item.team,
              score: item.score,
              hit: item.aciertos,
              challengeC: item.desafios.completados,
              challengeP: item.desafios.pendientes,
            }
          });
          setPlayerRank(players);

          let teamRankData = data.ranking.equipos.map((item, index) => {
            return {
              key: index + 1,
              position: item.puesto,
              equipment: item.name,
              member: item.integrante,
              score: 4000,
              hit: item.aciertos,
              challengeC: item.desafios.completados,
              challengeP: item.desafios.pendientes
            }
          });
          setTeamRank(teamRankData);
        });

      var event = new Date();
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      setDate(event.toLocaleDateString('es-ES', options));
      setTime(formatAMPM(event));
      setMountState(1);
      const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
      setUserName(loginInfo.firstName +' '+ loginInfo.lastName);
    }
  });
  const onSearch = (value) => {
    setPageTitle('');
    setPageSubTitle('');
    setSearchText(value);
  };

  const categoryTableColumn = [
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
      render: (category) => (
        <div style={{
            background: '#6A67CE',
            padding: '3px 12px',
            display: 'inline',
            border: '1px solid #6A67CE',
            borderRadius: '5px',
            color: '#FFFFFF'
        }}>{category}</div>
      )
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Aciertos',
      dataIndex: 'hit',
      key: 'hit',
    },
    {
      title: 'Manejo de categoría',
      dataIndex: 'manage',
      key: 'manage',
      render: (per) => (
        <Progress percent={per} status="active"  strokeColor="#6A67CE" />
      )
    },
  ];

  const questionTableColumn = [
    {
      title: 'Pregunta',
      dataIndex: 'ask',
      key: 'ask',
    },
    {
      title: 'Correctas',
      dataIndex: 'correct',
      key: 'correct',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Incorrectas',
      dataIndex: 'incorrect',
      key: 'incorrect',
      sorter: {
        compare: (a, b) => a.incorrect - b.incorrect,
        multiple: 3,
      }
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      sorter: {
        compare: (a, b) => a.total - b.total,
        multiple: 3,
      }
    },
  ];
  
  const rankTableColumn = [
    {
      title: 'Puesto',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Equipo',
      dataIndex: 'equipment',
      key: 'equipment',
    },
    {
      title: 'Puntaje',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: '% Aciertos',
      dataIndex: 'hit',
      key: 'hit',
    },
    {
      title: 'Desafíos completados',
      dataIndex: 'challengeC',
      key: 'challengeC',
    },
    {
      title: 'Desafíos pendientes',
      dataIndex: 'challengeP',
      key: 'challengeP',
    },
  ];
  
  const rankETableColumn = [
    {
      title: 'Puesto',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Equipo',
      dataIndex: 'equipment',
      key: 'equipment',
    },
    {
      title: 'Integrantes',
      dataIndex: 'member',
      key: 'member',
    },
    {
      title: 'Puntaje',
      dataIndex: 'score',
      key: 'score',
    },
    {
      title: '% Aciertos',
      dataIndex: 'hit',
      key: 'hit',
    },
    {
      title: 'Desafíos completados',
      dataIndex: 'challengeC',
      key: 'challengeC',
    },
    {
      title: 'Desafíos pendientes',
      dataIndex: 'challengeP',
      key: 'challengeP',
    },
  ];

  const [open, setOpen] = useState(false);
  const showModal = () => {
      setOpen(true);
  };
  const handleOk = (e) => {
      console.log(e);
      setOpen(false);
  };
  
  const handleCancel = (e) => {
      console.log(e);
      setOpen(false);
  };

  return (
    <div className='dashboard'>
        <div className='panel borderL flex-column h-center gap-bit b-shadow'>
            <div className='v-between  w-100 h-end'>
                <Title className='large-title no-margin'>Hola {username}</Title>
                <Text className='smail-title'>{date}</Text>
            </div>
            <div className='v-between  w-100'>
                <Text className='bit-title'>Bienvenido al Dashboard.</Text>
                <Text className='bit-title'>{time}</Text>
            </div>
        </div>
        <div className='panel borderL mt-medium b-shadow'>
            <div className='padding-bit v-between'>
                <Title className='large-title no-margin bold'>{pageTitle}</Title>
                <Search
                    placeholder="Busca un juego para visualizar resultados"
                    allowClear
                    value={pageTitle}
                    onSearch={onSearch}
                    style={{
                        width: "40%",
                    }}
                    onClick={showModal}
                />
            </div>
            <div className='padding-bit'>
              <h2 className='medium-title no-margin bold'>{pageSubTitle}</h2>
            </div>
            <div className='mt-medium padding-bit'>
                <Collapse 
                    bordered={false}
                    defaultActiveKey={['1']}
                >
                    <Panel 
                        header="1. Resultados Generales" key="1" 
                        className='collapse-panel mb-large'
                        extra={
                            <div onClick={e => e.stopPropagation()}>
                              <Button size="small"
                                   style={{
                                        borderRadius: "6px",
                                        fontFamily: 'Inter',
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        lineHeight: "17px",
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#6A67CE",
                                        height: 35
                                   }}
                                   onClick={() => {
                                    let idSearch = '';
                                    let typeSearch = '';
                                    if(selectedCompetition.id){
                                      idSearch=selectedCompetition.id;
                                      typeSearch='competition';
                                    }else if(selectedGame.id){
                                      idSearch = selectedGame.id
                                      typeSearch='game';
                                    }  
                                    downloadStaticAction({
                                        id: idSearch,
                                        type: typeSearch
                                      }, () => {
                                        console.log('success');
                                      }, () => {
                                        console.log('failure');
                                      })
                                   }}
                              >
                                <DownloadOutlined />
                                Descargar informe
                              </Button>
                            </div>
                        }
                    >
                        <div className='v-around'>
                            <div className='panel square-panel flex-column h-center'>
                                <img src="/img/icon/user.png"/>
                                <Text 
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '19.7593px',
                                        lineHeight: '24px',
                                        textAlign: 'center',
                                        color: '#4F4F4F !important',
                                    }}
                                >
                                    {joinedPer?joinedPer:0}%
                                </Text>
                                <Text 
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        color: '#4F4F4F',
                                        textAlign: 'center',
                                    }}
                                >
                                    % de Participación
                                </Text>
                            </div>
                            <div className='panel square-panel flex-column h-center'>
                                <img src="/img/icon/bright.png"/>
                                <Text 
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '19.7593px',
                                        lineHeight: '24px',
                                        textAlign: 'center',
                                        color: '#4F4F4F !important',
                                    }}
                                >
                                    {quizPer?quizPer:0}%
                                    
                                </Text>
                                <Text 
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        color: '#4F4F4F',
                                        textAlign: 'center',
                                    }}
                                >
                                    % de aciertos
                                </Text>
                            </div>
                            <div className='panel square-panel flex-column h-center'>
                                <img src="/img/icon/upload.png"/>
                                <Text 
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '19.7593px',
                                        lineHeight: '24px',
                                        textAlign: 'center',
                                        color: '#4F4F4F !important',
                                    }}
                                >
                                    {startCnt}
                                </Text>
                                <Text 
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        color: '#4F4F4F',
                                        textAlign: 'center',
                                    }}
                                >
                                    Desafíos lanzados
                                </Text>
                            </div>
                            <div className='panel square-panel flex-column h-center'>
                                <img src="/img/icon/down2.png"/>
                                <Text 
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '19.7593px',
                                        lineHeight: '24px',
                                        textAlign: 'center',
                                        color: '#4F4F4F !important',
                                    }}
                                >
                                    {completeCnt}
                                </Text>
                                <Text 
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        color: '#4F4F4F',
                                        textAlign: 'center',
                                    }}
                                >
                                    Desafios Contestados
                                </Text>
                            </div>
                            <div className='panel square-panel flex-column h-center'>
                                <img src="/img/icon/down.png"/>
                                <Text 
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '19.7593px',
                                        lineHeight: '24px',
                                        textAlign: 'center',
                                        color: '#4F4F4F !important',
                                    }}
                                >
                                    {pendingCnt}
                                </Text>
                                <Text 
                                    style={{
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                        lineHeight: '16px',
                                        color: '#4F4F4F',
                                        textAlign: 'center',
                                    }}
                                >
                                    Desafios Pendientes
                                </Text>
                            </div>
                        </div>
                    </Panel>
                    <Panel header="2. Desempeño por categoría de preguntas" key="2" className='collapse-panel mb-large'>
                        <Table
                            columns={categoryTableColumn}
                            dataSource={categoryList}
                            pagination={false}
                        />
                    </Panel>
                    <Panel header="3. Desempeño por pregunta" key="3" className='collapse-panel mb-large'>
                        <Table
                            columns={questionTableColumn}
                            dataSource={quizList}
                            pagination={false}
                        />
                    </Panel>
                    <Panel header="4. Ranking jugadores" key="4" className='collapse-panel mb-large'>
                        <Table
                            columns={rankTableColumn}
                            dataSource={playerRank}
                            pagination={false}
                        />
                    </Panel>
                    <Panel header="5. Ranking equipos" key="5" className='collapse-panel mb-large'>
                        <Table
                            columns={rankETableColumn}
                            dataSource={teamRank}
                            pagination={false}
                        />
                    </Panel>
                </Collapse>
            </div>
        </div>
        <Modal
            title=''
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            width="60%"
            height="80%"
            style={{
              // top: "0",
              paddingBottom: "0px",
            }}
            footer=''
        >
            <div className='modal-content' style={{border: "none", height: "100%", display: "block", overflowY: "auto"}}>
              <Text className='header-title color-back'>Búsqueda</Text><br/>
              <Text className='medium-title'>Para visualizar los resultados, busca la competencia, juego o jugador que desees.</Text><br/>
              <div className='mt-medium'>
                <Button className={`bit-button hm ${modalStep !== 'game' ? 'back-grey': '' }`} onClick={() => setModalStep('game')}>Juegos</Button>
                <Button className={`bit-button hm ml-bit ${modalStep !== 'skill' ? 'back-grey': '' }`} onClick={() => setModalStep('skill')}>Competencias</Button>
                {/* <Button className={`bit-button hm ml-bit ${modalStep !== 'player' ? 'back-grey': '' }`} onClick={() => setModalStep('player')}>Jugadores</Button> */}
              </div>
              <Search
                  className='mt-small hm'
                  placeholder="Busca un juego para visualizar resultados"
                  allowClear
                  onSearch={onSearch}
                  style={{
                      width: "50%",
                  }}
                />
              <ModalSearch 
                mode={modalStep} 
                searchText={searchText} 
                setSearchText={setSearchText}
                setSelectedUser={setSelectedUser}
                selectedUser={selectedUser}
                setSelectedCompetition={(data) => {
                  setSelectedCompetition(data);
                  handleCancel();
                }}
                selectedCompetition={selectedCompetition}
                setSelectedGame={setSelectedGame}
                selectedGame={selectedGame}
                handleCancel={handleCancel}
                setMountState={setMountState}
              />
            </div>
        </Modal>
    </div>
  );
}
