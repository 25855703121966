import axios from 'axios';
import {SERVER_URL} from '../../constant';
import api from '../../api/interceptor';

export const addPlayerAction = (addData, callback, err) => {
    // const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    // var config = {
    //     method: 'post',
    //     url: SERVER_URL + 'admin/user/invite',
    //     crossdomain: true,
    //     headers: { 
    //         'Content-Type': 'application/json',
    //         'x-access-token': loginInfo.accessToken
    //     },
    //     data : addData,
    // };
    // console.log(config);
    // axios(config)
    api.post('admin/user/invite', addData)
    .then(function (res) {
        res.status === 200 ? callback(res.data) : err(res.data);
        return res.data;
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const downloadTemplate = async (callback) => {
    axios.get(SERVER_URL + 'excel/user/download', {
        method: 'GET',
        responseType: 'blob', // important
        crossdomain: true,
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `user_excel.xlsx`);
        document.body.appendChild(link);
        link.click();
    });
}

export const addMultiPlayerAction = (addDatas, callback, err) => {
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/user_multi/add',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : {players: addDatas},
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data) : err(res.data);
    })
    .catch(function (error) {
        err(error);
        console.log(error);
    });
}

export const editPlayerAction = (updateData, callback, err) => {
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/user/edit',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : updateData,
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback() : err();
    })
    .catch(function (error) {
        err();
        console.log(error);
    });
}

export const getPlayersAction = (callback) => {
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'get',
        url: SERVER_URL + 'admin/user/list',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'x-access-token': loginInfo.token
        },
    };

    api.post('admin/user/list')
    .then(function (res) {
        console.log(res);
        callback(res.data.list);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getDepartmentAction = (callback, err) => {
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/department/list',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'x-access-token': loginInfo.accessToken
        },
    };

    api.post('admin/department/list')
    .then(function (res) {
        console.log(res);
        res.success === 200 ? callback(res.data.list) : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const removePlayerAction = (ids, callback, err) => {
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/user/delete',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : {
            ids: ids
        },
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const changeStateAction = (data, callback, err) => {
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/user/change_state',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : data,
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const addAdminAction = (addData, callback, err) => {
    api.post('admin/subadmin/invite', addData)
    .then(function (res) {
        console.log("New Admin Res ====", res);
        res.status === 200 ? callback(res.data) : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const editAdminAction = (editId, updateData, callback, err) => {
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/subclient/edit',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : {
            updateData: updateData,
            editId: editId
        },
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const changeStateAdminAction = (id, callback, err) => {
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/subclient/changestate',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : {
            id: id
        },
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getAdminsAction = (callback, err) => {
    api.post('admin/sub-admin/list')
    .then(function (res) {
        console.log("=====ADMINS",res);
        callback(res.data.list);
    })
    .catch(function (err) {
        console.log(err);
    });
}

export const removeAdminsAction = (ids, callback, err) => {
    // const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    // var config = {
    //     method: 'post',
    //     url: SERVER_URL + 'admin/subclient/remove',
    //     crossdomain: true,
    //     headers: { 
    //         'Content-Type': 'application/json',
    //         'x-access-token': loginInfo.accessToken
    //     },
    //     data : {
    //         ids: ids
    //     },
    // };

    api.post('admin/sub-admin/delete', {ids: ids})
    .then(function (res) {
        console.log(res);
        res.success === 200 ? callback(res.data.list) : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const playerRankingAction = (id, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'get',
        url: SERVER_URL + 'admin/player_ranking/' + id,
        crossdomain: true,
        headers: {
            'x-access-token': loginInfo.accessToken
        }
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data.rank.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const playerRankingByCompetitionAction = (id, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'get',
        url: SERVER_URL + 'admin/player_ranking_competition/' + id,
        crossdomain: true,
        headers: {
            'x-access-token': loginInfo.accessToken
        }
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data.rank.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const sendInviteCode = (data, callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'auth/sendmail',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.accessToken}`
        },
        data : data,
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback() : err();
    })
    .catch(function (error) {
        err();
        console.log(error);
    });
}

export const forgotPassword = (inviteData, callback, err) => {
    api.post('/admin/auth/forgot-password', inviteData)
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getSelectedPlayers = (data, callback, err) => {
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/competition/selectedPlayers',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : data,
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data.result) : err(res.data.result);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const setTeamNameAction = (data, callback, err) => {
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/competition/setTeamName',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : data,
    };

    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data.result) : err(res.data.result);
    })
    .catch(function (error) {
        console.log(error);
    });
}