import { Button, Modal, Input, Typography, Collapse, Table, Spin, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactInputVerificationCode from "react-input-verification-code";
import styled from "styled-components";
import { ConsoleSqlOutlined, DownloadOutlined } from '@ant-design/icons';
import { FaUserCheck, FaUserLock } from "react-icons/fa";
import InfoModal from "../../components/player/InfoModal";
import CodeModal from "../../components/player/CodeModal";
import { FiEdit } from "react-icons/fi";
import { BsFillShareFill } from "react-icons/bs";
import { ExcelRenderer } from "react-excel-renderer";

import { 
  addPlayerAction, 
  addMultiPlayerAction, 
  editPlayerAction,
  getPlayersAction,
  sendInviteCode,
  removePlayerAction,
  changeStateAction,
  downloadTemplate,
} from '../../components/actions/Player';
import { formatAMPM } from '../../function';
import '../../scss/dashboard.scss';

const StyledReactInputVerificationCode = styled.div`
       display: flex;
       justify-content: center;

       --ReactInputVerificationCode-itemWidth: 40px;
       --ReactInputVerificationCode-itemHeight: 40px;
       --ReactInputVerificationCode-itemSpacing: 8px;

       .ReactInputVerificationCode__item {
           font-size: 16px;
           font-weight: 500;
           color: #ABB4C5;
           border: 1px solid
           ${({ isInvalid }) => (isInvalid ? "#EF6C65" : "rgba(28, 30, 60, 0.4)")};
           border-radius: 4px;
           line-height: 40px;
           box-shadow: none;
           background: #fff;
       }

       .ReactInputVerificationCode__item.is-active {
           box-shadow: none;
           border: 1px solid #36c6d9;
       }
       `;

const { Text, Title, Link } = Typography;
const { Search } = Input;
const { Panel } = Collapse;

export default function PlayerPage() {
  const navigate = useNavigate();

  const [modalTab, setModalTab] = useState(1);
  const [verifyCode, setVerifiCode] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [listState, setlistState] = useState(0);
  const [playerList, setPlayerList] = useState([]);
  const [showPlayerList, setShowPlayerList] = useState([]);
  const [playerCnt, setPlayerCnt] = useState(0);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [username, setUserName] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [editId, setEditId] = useState(0);
  const [department, setDepartment] = useState('');
  const [addLoading, setAddLoading] = useState(0);
  const [err, setErr] = useState('');
  const [excelLoading, setExcelLoading] = useState(0);
  const [changeStateId, setChangeStateId] = useState(-1);
  const [changeStateUserName, setChangeStateUserName] = useState('');
  const [changeStateVal, setChangeStateVal] = useState(-1);
  const [changeStateLoading, setChangeStateLoading] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(0);
  const [searchText, setSearchText] = useState('');

  const onSearch = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    let showList = [];
    for (let i in playerList) {
      let p = playerList[i];
      if (p.firstName.includes(searchText) || p.lastName.includes(searchText)) {
        showList.push(p);
      }
    }
    setShowPlayerList(showList);
  }, [playerList, searchText]);

  const init = () => {
    setName('');
    setSurname('');
    setEmail('');
    setEditId(0);
  }

  useEffect(() => {
    if (!listState) {
      setlistState(1);
      getPlayersAction((users) => {
        console.log("Usuarios: ", users);
        let userlist = users.map((user) => {
          return {
            firstName: user.firstName,
            lastName: user.lastName,
            department: user.department,
            ruth: user.ruth,
            state: user.state,
            email: user.email,
            id: user._id,
            code: user.inviteCode,
            key: user._id,
          }
        });
        setPlayerCnt(userlist.length);
        setPlayerList(userlist);
      });
    }
    var event = new Date();
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    setDate(event.toLocaleDateString('es-ES', options));
    setTime(formatAMPM(event));
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    setUserName(loginInfo.firstName +' '+ loginInfo.lastName);
  });

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let selected = selectedRows.map(row => {
        return row.key;
      });
      setSelectedIds(selected);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  
  const action = () => {
    if (editId === 0) addPlayer();
    if (editId !== 0) editPlayer();
  }

  const [open, setOpen] = useState(false);
  const showAddModal = () => {
      init();
      setOpen(true);
  };
  const showEditmodal = (data) => {
      setName(data.name);
      setSurname(data.surname);
      setEmail(data.email);
      setEditId(data.id);
      setOpen(true);
  };
  const handleOk = (e) => {
      console.log(e);
      setOpen(false);
  };
  
  const handleCancel = () => {
      setOpen(false);
  };
  
  const [openInvite, setOpenInvite] = useState(false);
  const [inviteCodeModal, setInviteCodeModal] = useState('');
  const [inviteData, setInviteData] = useState('');
  const showModalInvite = (data) => {
    setInviteCodeModal(data.text);
    setInviteData(data);
    setOpenInvite(true);
    console.log(data);
  };
  const handleOkInvite = (e) => {
    setOpenInvite(false);
  };
  
  const handleCancelInvite = (e) => {
    setOpenInvite(false);
  };

  const competationColumn = [
    {
      title: 'Nombre',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Apellido',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Rut',
      dataIndex: 'ruth',
      key: 'ruth',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Departamento',
      dataIndex: 'department',
      key: 'department',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      },
      render: (_, record) => (
        <>
          {
            record.state === true ? "Activo" : "Inactivo"
          }
        </>
      )
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className='v-around h-center'>
          <FiEdit onClick={() => {showEditmodal({id: record.id , name: record.firstName, surname: record.lastName, email: record.email})}} style={{color: "#8146ff", fontSize: "20px"}}/>
          <BsFillShareFill onClick={() => showModalInvite({toAddress: record.email, text: record.code})} style={{color: "#8146ff", fontSize: "20px"}}/>
          {
            record.state == false ?
              <FaUserLock onClick={() => changeState(record.id, 0)} style={{color: "#F26E6E", fontSize: "20px"}} />
              :
              <FaUserCheck onClick={() => changeState(record.id, 1)} style={{color: "#56C456", fontSize: "20px"}} />
          }
        </div>
      )
    },
  ];

  const setfuncText = () =>{
    setAddLoading(1); 
    const addData = {
      firstName: name,
      lastName: surname,
      department: department,
      email: email.trim()
    };
    addPlayerAction(
      addData, (data) => {
        // sendInvite({toAddress: email, text: verifyCode});
        console.log('success');
        setAddLoading(0);
        setlistState(0); 
        setVerifiCode(data.inviteCode);
        setModalTab(2);
      }, () => {
        console.log('failure');
        setAddLoading(0);
        setModalTab(1);
      }
    );    
  }



  const addPlayer = () => {
    setAddLoading(1);
    const addData = {
      firstName: name,
      lastName: surname,
      department: department,
      email: email.trim(),
      inviteCode: verifyCode,
    };
    const a = 
    addPlayerAction(
      addData, (data) => {
        // sendInvite({toAddress: email, text: verifyCode});
        console.log('success');
        setAddLoading(0);
        setlistState(0); 
        handleCancel();
        setModalTab(1);
        console.log("es la data: " ,data);
        setVerifiCode(data.inviteCode)
      }, () => {
        console.log('failure');
        setAddLoading(0);
        setModalTab(1);
      }
    );
  }

  const editPlayer = () => {
    setAddLoading(1);
    const editData = {
      editId: editId,
      firstName: name,
      lastName: surname,
      department: department,
      email: email.trim(),
      inviteCode: verifyCode,
    };
    editPlayerAction(
      editData, () => {
        console.log('success');
        setlistState(0);
        setAddLoading(0);
        handleCancel();
        setModalTab(1); 
      }, () => {
        console.log('failure');
        setAddLoading(0);
        setModalTab(1); 
      }
    );
  }

  const removePlayer = () => {
    showModalDelete();
  }

  const removePlayerConfirm = () => {
    setDeleteLoading(1);
    removePlayerAction(selectedIds, () => {
      setlistState(0);
      handleCancelDelete();
      setDeleteLoading(0);
    }, () => {
      handleCancelDelete();
      setDeleteLoading(0);
    });
  }

  const changeState = (userId) => {
    setChangeStateId(userId);
    for (let i in playerList) {
      let p = playerList[i];
      if (p.id == userId) {
        setChangeStateUserName(p.firstName + " " + p.lastName);
        setChangeStateVal(p.state);
      }
    }
    showModal1();
  }

  const changeStateConfirm = () => {
    setChangeStateLoading(1);
    changeStateAction({
      user_id: changeStateId,
    }, () => {
      setlistState(0);
      handleCancel1();
      setChangeStateId(-1);
      setChangeStateLoading(0);
    }, () => {
      handleCancel1();
      setChangeStateId(-1);
      setChangeStateLoading(0);
    });
  }

  const sendInvite = (inviteData) => {
    sendInviteCode(inviteData, () => {
      handleCancelInvite();
    });
  }
  
  const [open1, setOpen1] = useState(false);
  const showModal1 = () => {
      setOpen1(true);
  };
  const handleOk1 = (e) => {
      console.log(e);
      setOpen1(false);
  };
  
  const handleCancel1 = (e) => {
      console.log(e);
      setOpen1(false);
  };
  
  const [openDelete, setOpenDelete] = useState(false);
  const showModalDelete = () => {
      setOpenDelete(true);
  };
  const handleOkDelete = (e) => {
      console.log(e);
      setOpenDelete(false);
  };
  
  const handleCancelDelete = (e) => {
      console.log(e);
      setOpenDelete(false);
  };

  const fileHandler = fileList => {
    console.log("fileList", fileList);
    let fileObj = fileList;
    if (!fileObj) {
      setErr("No file uploaded!");
      return false;
    }
    console.log("fileObj.type:", fileObj.type);
    if (
      !(
        fileObj.type === "application/vnd.ms-excel" ||
        fileObj.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      setErr("Unknown file format. Only Excel files are uploaded!");
      return false;
    }
    setExcelLoading(1);
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
        setExcelLoading(0);
      } else {
        let newRows = [];
        resp.rows.slice(1).map((row, index) => {
          if (row && row !== "undefined") {
            if (!row[0]) return;
            newRows.push({
              firstName: row[0],
              lastName: row[1],
              email: row[2],
            });
          }
        });
        if (newRows.length === 0) {
          setErr("No data found in file!");
          setExcelLoading(0);
          return false;
        } else {
          addMultiPlayerAction(newRows, (data) => {
              console.log(data);
              setExcelLoading(0);
              setlistState(0);
            }, (err) => {
              setErr("failure");
              setExcelLoading(0);
            }
          );
        }
      }
    });
    return false;
  };
  
  return (
    <div className='dashboard'>
        <div className='panel borderL flex-column h-center gap-bit b-shadow'>
            <div className='v-between  w-100 h-end'>
                <Title className='large-title no-margin'>Hola {username}</Title>
                <Text className='smail-title'>{date}</Text>
            </div>
            <div className='v-between  w-100'>
                <Text className='bit-title'>En esta sección podras agregar jugadores, ya sea de forma manual o con ayuda de nuestra plantilla disponible.</Text>
                <Text className='bit-title'>{time}</Text>
            </div>
        </div>
        <div className='panel borderL mt-medium b-shadow'>
            <div className='v-between'>
                <Title className='large-title no-margin bold'>Jugadores ({playerCnt})</Title>
                <div className='v-end h-center'>
                  <Search
                      placeholder="Buscar usuario"
                      allowClear
                      onSearch={onSearch}
                      style={{
                          width: "30%",
                      }}
                  />
                    <Button className={`bit-button hs ml-bit`} type="primary" onClick={() => downloadTemplate()}>
                      Descargar Plantilla
                    </Button>
                    <Upload 
                      name="file"
                      showUploadList={false}
                      beforeUpload={fileHandler}
                    >
                      <Button disabled={excelLoading ? true : false} className={`bit-button hs ml-bit`} type="primary">
                        {
                          !excelLoading ?
                            "Importar Plantilla"
                            :
                            <Spin size="small"/>
                        }
                      </Button>
                    </Upload>
                    <Button className={`bit-button hs ml-bit`} type="primary" onClick={() => showAddModal()}>
                      Agregar jugadores
                    </Button>
                </div>
            </div>
            <div className='mt-large'>
              <Table
                columns={competationColumn}
                dataSource={showPlayerList}
                pagination={false}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            </div>
            <div className='v-center mt-large'>
                <Button className={`bit-button hs ml-bit`} type="primary">
                  Descargar Informe
                </Button>
                <Button className={`bit-button hs ml-bit`} onClick={() => removePlayer()} type="primary">
                  Eliminar
                </Button>
            </div>
        </div>
        <Modal
            title={modalTab == 1 ? (editId > 0 ? "Editar Jugador" : "Ingreso nuevo jugador") : 'Ingreso nuevo usuario'}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            width="70%"
            // height="60%"
            style={{
              paddingBottom: "0px",
            }}
            footer=''
            className='business-modal'
        >
            <div className='modal-content flex-column v-between h-center' style={{border: "none", height: "100%"}}>
                <div className='w-100'>
                  {modalTab == 1 ? <InfoModal setName={setName} setSurname={setSurname} setEmail={setEmail} setDepartment={setDepartment} department={department} name={name} surname={surname} email={email} editId={editId}/> : <CodeModal setVerify={verifyCode} />}
                </div>
                {modalTab == 1 ? 
                  <div className='w-100 v-center mt-large'>
                    <Button className='bit-button hs' onClick={() => handleCancel()} type="primary">Volver</Button>
                    <Button className='bit-button hs ml-medium' onClick={() => setfuncText()} type="primary">Agregar</Button>
                  </div>
                  : 
                  <div className='w-100 v-center mt-large'>
                    <Button disabled={addLoading ? true : false} className='bit-button hs' onClick={() => handleCancel()} type="primary">
                      {
                        !addLoading ? 
                          "Finalizar"
                          :
                          <Spin size="small"/>
                      }
                    </Button>
                  </div>
                }
            </div>
        </Modal>
        
        <Modal
            title=''
            open={openInvite}
            onOk={handleOkInvite}
            onCancel={handleCancelInvite}
            footer=''
        >
            <div className="v-center padding-medium" >
              <div className="flex-column h-center" style={{width: "70%"}}>
                <Text className="large-title color-b mt-medium">Compartir código por email</Text>    
                <StyledReactInputVerificationCode className="mt-bit mt-bit mt-medium">
                    <ReactInputVerificationCode length={5} placeholder={0} type='string' value={inviteCodeModal}/>
                </StyledReactInputVerificationCode>
                <Text className="medium-title mt-bit text-center mt-medium">Enviaremos el codigo de acceso al correo añadido del usuario</Text>
                <div className='mt-medium' style={{display: "flex"}}>
                  <Button className='bit-button hs' type="primary" onClick={() => handleCancelInvite()}>Volver</Button>
                  <Button className='bit-button hs ml-medium' onClick={() => sendInvite(inviteData)} type="primary">Enviar Correo</Button>
                </div>
              </div>
            </div>
        </Modal>
        
        <Modal
            title=''
            open={open1}
            onOk={handleOk1}
            onCancel={handleCancel1}
            footer=''
        >
            <div className='modal-content' style={{border: "none"}}>
                {
                  changeStateVal === false ?
                    <img src='/img/icon/active.png' width="91"/>
                    :
                    <img src='/img/icon/warning.png' width="91"/>
                }
                {
                  changeStateVal === false ?
                    <h3>Reactivar Jugador</h3>
                    :
                    <h3>Pausar Jugador</h3>
                }
                {
                  changeStateVal === false ?
                    <Text className='medium-title text-center'>¿Estás seguro de que quieres  reactivar la actividad de {changeStateUserName}?</Text>
                    :
                    <Text className='medium-title text-center'>¿Estas seguro de que quieres  pausar la actividad de {changeStateUserName}?</Text>
                }
                {
                  changeStateVal === false ?
                    ""
                    :
                    <p>Esto significa que {changeStateUserName} no podra ingresar nuevamente al sitio web impidiendo sus actividades</p>
                }
                
                <div>
                  <Button className='bit-button hs' type="primary" onClick={() => handleCancel1()}>Volver</Button>
                  <Button className='bit-button hs ml-medium' onClick={() => changeStateConfirm()} type="primary">
                    {
                      changeStateLoading == 1 ?
                        <Spin size="small"/>
                        :
                        (
                          changeStateVal === false ?
                            "Activar" 
                            :
                            "Pausar"
                        )
                    }
                  </Button>
                </div>
            </div>
        </Modal>
        
        <Modal
            title=''
            open={openDelete}
            onOk={handleOkDelete}
            onCancel={handleCancelDelete}
            footer=''
        >
            <div className='modal-content' style={{border: "none"}}>
                
                <img src='/img/icon/warning.png' width="91"/>
                <h3>Eliminar Jugador</h3>
                <Text className='medium-title text-center'>¿Estas seguro de que quieres  eliminar?</Text>
                <div>
                  <Button className='bit-button hs' onClick={() => handleCancelDelete()} type="primary">Volver</Button>
                  <Button className='bit-button hs ml-medium' onClick={() => removePlayerConfirm()} type="primary">
                    {
                      deleteLoading == 1 ?
                        <Spin size="small"/>
                        :
                        "Eliminar"
                    }
                  </Button>
                </div>
            </div>
        </Modal>
        
    </div>
  );
}
