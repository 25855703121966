import React, { useState, useEffect } from "react";
import { Typography, Button, Col, Row, Input, Table, List, Form, Select, Spin, Modal, Checkbox } from 'antd';
import { FiFilter } from "react-icons/fi";
import VirtualList from 'rc-virtual-list';
import { TiDeleteOutline } from "react-icons/ti";
import { BsArrowRightCircle } from "react-icons/bs";
import { LoadingOutlined } from '@ant-design/icons';
import newOTP from 'otp-generators';

import { GAME_MODAL_STEP } from "../../constant";
import { getPlayersAction, addPlayerAction, getDepartmentAction } from '../../components/actions/Player';
import { getCommonQuestions } from '../../components/actions/Other';
const { Search } = Input;
const { Option } = Select;

const { Text, Link } = Typography;
const ContainerHeight = "calc(100% - 200px)";
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const ChoosePlayerComponent = (props) => {
  const next = props.next;
  const previous = props.previous;
  const setPropsSelectedPlayer = props.setSelectedPlayer;
  const propsSelectedPlayer = props.selectedPlayer;
  const [mode, setMode] = useState("select");
  const [selectedPlayer, setSelectedPlayer] = useState([]);
  const [listState, setlistState] = useState(0);
  const [playerList, setPlayerList] = useState([]);
  const [showTableList, setShowTableList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [listData, setListData] = useState([]);
  const [addPlayerFullName, setAddPlayerFullName] = useState('');
  const [addPlayerLastName, setAddPlayerLastName] = useState('');
  const [addPlayerEmail, setAddPlayerEmail] = useState('');
  const [addPlayerDepartment, setAddPlayerDepartment] = useState('');
  const [addPlayerVerifyCode, setAddPlayerVerifyCode] = useState('');
  const [loadingState, setLoadingState] = useState(false);
  const [departmentInputState, setDepartmentInputState] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [checked, setChecked] = useState([]);
  const [currentRowSelect, setCurrentRowSelect] = useState([]);
  const [alternativeList, setAlternativeList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [selectedDepart, setSelectedDepart] = useState({});
  
  useEffect(() => {
    if (!listState) {
      getPlayersAction((users) => {
        let userlist = users.map((user) => {
          return {
            branch: '',
            firstName: user.firstName,
            department: user.department,
            email: user.email,
            key: user._id,
          }
        });

        setPlayerList(userlist);
        setlistState(1);
        resetPlayers()
      });

      getDepartmentAction((list) => {
        let departs = []
        list.map((l) => {
          departs.push(l.department);
        })
        departs.push('Otro')
        setDepartmentList(departs);
        let departPushList = [];
        departPushList.push({
          depart: "Departamento",
          list: departs,
        });

        getCommonQuestions((list) => {
          list.map((l) => {
            departPushList.push({
              depart: l.keyword,
              list: l.answers,
              id: l.id,
            });
          });
          setFilterList(departPushList);
        });
      })
    }
  });

  useEffect(() => {
    selectDepart(0);
  }, [filterList])

  useEffect(() => {
    let showList = [];
    if (searchText != '') {
      playerList.map((p) => {
        if (p.firstName.includes(searchText)) {
          showList.push(p);
        }
      });
    } else {
      showList = playerList;
    }
    setShowTableList(showList);
  }, [playerList, searchText]);

  const onSearch = (value) => {
    setSearchText(value.trim());
  };
  
  const playerColumn = [
    {
      title: 'Nombre',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: (
        <Button className="filter-button" onClick={() => showModal()} style={{width: "100%"}}>
          <Text className="small-title">Departamento</Text>
          <FiFilter style={{fontSize: "16px", color: "#6A67CE"}}/>
        </Button>
      ),
      dataIndex: 'department',
      key: 'department',
    },
  ];

  useEffect(() => {
    let selectedPlayersIds = [];
    let selectedUserList = [];
    selectedPlayer.map((p) => {
      selectedPlayersIds.push(p.key)
      selectedUserList.push({
        firstName: p.firstName,
        department: p.department,
        email: p.email,
        key: p.key
      })
    });

    let unselected = [];
    playerList.map((p) => {
      if (!selectedPlayersIds.includes(p.key))
        unselected.push(p)
    });console.log(unselected);

    setPlayerList(unselected);
    setSelectedPlayer(selectedPlayer);
    setListData(selectedUserList);
  }, [selectedPlayer]);

  const resetPlayers = () => {
    setSelectedPlayer([
      ...selectedPlayer,
      ...currentRowSelect
    ]);
  }

  const addPlayer = () => {
    setLoadingState(true);
    let OTP = newOTP.generate(5, { alphabets: true, upperCase: true, specialChar: false });
    
    const addData = {
      firstName: addPlayerFullName,
      lastName: addPlayerLastName,
      department: addPlayerDepartment,
      email: addPlayerEmail.trim(),
      inviteCode: OTP,
    };
    addPlayerAction(
      addData, (data) => {
        setLoadingState(false);
        setPlayerList([
          ...playerList,
          {
            firstName: addPlayerFullName,
            department: addPlayerDepartment,
            email: addPlayerEmail.trim(),
            key: data.id,
          }
        ]);
        // setlistState(0);
        setMode('select');
      }, () => {
        setLoadingState(false);
      }
    );
  }
  
  const formLayout = 'vertical';
  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setCurrentRowSelect(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const onScroll = (e) => {
     console.log();
  };
  const removePlayer = (key) => {
    console.log(listData);
    let unremovedData = [];
    let removedData = {};
    selectedPlayer.map(l => {
      if (l.key !== key) {
        unremovedData.push(l);
      } else {
        removedData = l;
      }
    });
    setPlayerList([
      ...playerList,
      removedData
    ]);
    setSelectedPlayer(unremovedData);
  }
  
  const handleDepartChange = (value) => {
    if (value == 'Otro') {
      setAddPlayerDepartment('');
      setDepartmentInputState(true);
    } else {
      setDepartmentInputState(false);
      setAddPlayerDepartment(value);
    }
  };

  const toNextStep = () => {
    setPropsSelectedPlayer(selectedPlayer);
    next(GAME_MODAL_STEP.CHOOSE_TEAM)
  }

  const searchBy = () => {
    let players = [];
    if (checked.length == 0) {
      setlistState(0);
      handleCancel();
      return;
    }
    playerList.map((p) => {
      if (checked.includes("Otro")) {
        setlistState(0);
      } else if (selectedDepart.depart == "Departomento") {
        if (checked.includes(p.departamento)) {
          players.push(p);
        }
      } else if (JSON.parse(p.branch)) {
        JSON.parse(p.branch).map((b) => {
          if (b.id == selectedDepart.id) {
            if (checked.includes(b.value) ) {
              players.push(p);
            }
          }
        })
      }
    });
    setShowTableList(players);
    handleCancel();
  }
  
  const [open, setOpen] = useState(false);
  // const [inviteCodeModal, setInviteCodeModal] = useState('');
  // const [inviteData, setInviteData] = useState('');
  const showModal = (data) => {
    setOpen(true);
    console.log(data);
  };
  const handleOk = (e) => {
    setOpen(false);
  };
  
  const handleCancel = (e) => {
    setOpen(false);
  };

  const selectDepart = (index) => {
    setSelectedDepart(filterList[index]);
  }

  return (
    <div className="panel" 
      style={{
        paddingLeft: "65px",
        paddingRight: "65px",
        paddingTop: "0px",
        height: "100%",
      }}
    >
      <div className='panel padding-small large-title pv-medium color-w'
        style={{
          borderRadius: "10px 10px 0px 0px",
          background: "#6A67CE",
        }}
      >
        Elige a los jugadores de esta competencia
      </div>
      <div className="padding-small">
        <Row>
          <Col span={15}>
            <div className="">
              <Button className="small-button hm" onClick={() => setMode("select")}>Jugadores</Button>
              <Button className="small-button hm ml-bit" onClick={() => setMode("input")}>Añadir nuevo jugador</Button>
            </div>
          </Col>
          <Col span={9}>
            <Search
              className='hm'
              placeholder="Ingresa nombre ,email del jugador o departamento"
              allowClear
              onSearch={onSearch}
              style={{
                  width: "100%",
              }}
            />
          </Col>
        </Row>
      </div>
      <div  
        style={{
          height: "calc(100% - 70px)"
        }}
      >
        <Row style={{height: "100%"}}>
          <Col span={14} style={{height: "100%"}} className="choose-player-table">
            {
              mode === "select" ?
                (<>
                <Table
                  columns={playerColumn}
                  dataSource={showTableList}
                  pagination={false}
                  scroll={{
                    y: "100",
                  }}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                />
                <div className="w-100 v-center">
                  <Button className="small-button hm" onClick={() => resetPlayers()}>Añadir seleccionados</Button>
                </div>
                </>)
              : 
                (<Form
                  {...formItemLayout}
                  layout={formLayout}
                >
                  <Spin spinning={loadingState} indicator={antIcon}>
                    <Text className="medium-title mt-small">Ingresa los datos requeridos para agregar a un nuevo jugador</Text>
                    <Form.Item label="Nombre del jugador" className="mt-medium">
                      <Input 
                        placeholder="Ingrese Nombre del jugador" 
                        className='hm' 
                        style={{width: "80%"}}
                        onChange={(e) => setAddPlayerFullName(e.target.value)}
                        value={addPlayerFullName}
                      />
                    </Form.Item>
                    <Form.Item label="Apellido del jugador" className="mt-medium">
                      <Input 
                        placeholder="Ingrese Apellido del jugador" 
                        className='hm' 
                        style={{width: "80%"}}
                        onChange={(e) => setAddPlayerLastName(e.target.value)}
                        value={addPlayerLastName}
                      />
                    </Form.Item>
                    <Form.Item label="Departamento" className="mt-medium" style={{display:'none'}}>
                      <div className="flex w-100">
                        <Select
                          defaultValue="Indique un departamento"
                          style={{
                            width: "50%",
                            height: "40px"
                          }}
                          placeholder="Indique un departamento"
                          onChange={(val) => handleDepartChange(val)}
                          value={addPlayerDepartment}
                        >
                          {departmentList.map((d) => (
                            <Option key={d}>{d}</Option>
                          ))}
                        </Select>
                        {
                          departmentInputState ? 
                            <Input 
                              placeholder="Otro" 
                              className='hm' 
                              style={{width: "30%"}}
                              onChange={(e) => setAddPlayerDepartment(e.target.value)}
                              value={addPlayerDepartment}
                            />
                            :
                            ""
                        }
                      </div>
                    </Form.Item>
                    <Form.Item label="Email" className="mt-medium">
                      <Input 
                        placeholder="Ingrese un email" 
                        className='hm' 
                        style={{width: "80%"}}
                        onChange={(e) => setAddPlayerEmail(e.target.value)}
                        value={addPlayerEmail}
                      />
                    </Form.Item>
                    <div className="w-100 v-center">
                      <Button 
                        className={`small-button hm ${addPlayerFullName == "" || addPlayerLastName == "" || addPlayerEmail == "" ||  addPlayerDepartment == "" ? "back-grey" : ""}`}
                        onClick={() => addPlayer()}
                        disabled={addPlayerFullName == "" || addPlayerLastName == "" ||  addPlayerEmail == "" ? true : false}
                      >Añadir  jugador</Button>
                    </div>
                  </Spin>
                </Form>)
            }
            
          </Col>
          <Col span={10} style={{height: "100%"}}>
              <div className="choose_player_panel" style={{paddingLeft: "40px", height: "100%"}}>
                <div className="pv-medium"
                  style={{
                    background: "#E8E8FF",
                    borderRadius: "10px 10px 0px 0px",
                    // padding: "0px",
                    fontFamily: 'Inter',
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: "64px",
                    color: "#4F4F4F",
                  }}
                >
                  Jugadores añadidos: {listData.length}
                </div>
                <List>
                  <VirtualList
                    data={listData}
                    height={ContainerHeight}
                    itemHeight={47}
                    itemKey="key"
                    onScroll={onScroll}
                  >
                    {(item, index) => (
                      <List.Item key={item.key}>
                        <List.Item.Meta
                          title={<div className="v-between pv-large list-title">
                            {"# " + (index+1) + "  " + item.firstName + " "} 
                            <TiDeleteOutline onClick={() => removePlayer(item.key)} style={{color: "#EB5757", fontSize: "20px"}}/>
                          </div>}
                        />
                      </List.Item>
                    )}
                  </VirtualList>
                </List>
                <div className="w-100 v-center" style={{marginTop: "34px"}}>
                  <Button className={`small-button hm ${listData.length!==0?"":"back-grey"}`} disabled={listData.length!==0?false:true} onClick={() => toNextStep()}>Continuar</Button>
                </div>
              </div>
          </Col>
        </Row>
      </div>
      <Modal
          title=''
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          footer=''
      >
          <div className="v-center padding-medium" >
            <div className="flex-column h-center" style={{width: "100%"}}>
              <Row className="w-100 v-between">
                <Col span={11}>
                  <List>
                    <VirtualList
                      data={filterList}
                      height={300}
                      itemHeight={47}
                      itemKey="key"
                      onScroll={onScroll}
                    >
                      {(item, index) => (
                        <List.Item onClick={() => selectDepart(index)} key="">
                          <List.Item.Meta
                            title={
                              <div className="v-between h-center">
                                {item.depart}
                                <BsArrowRightCircle style={{color: "#5E5BC0"}}/>
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    </VirtualList>
                  </List>
                </Col>
                <Col span={11}>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    value={checked}
                    onChange={(checkedValues) => {
                      setChecked(checkedValues);
                    }}
                  >
                    <List>
                      <VirtualList
                        data={selectedDepart?selectedDepart.list:[]}
                        height={300}
                        itemHeight={47}
                        itemKey="key"
                        onScroll={onScroll}
                      >
                        {(item, index) => (
                          <List.Item 
                            key=""
                          >
                            <List.Item.Meta
                              title={
                                <div className="v-between h-center">
                                  {item}
                                  {/* <Checkbox value={{detail: selectedDepart, item: item}} /> */}
                                  <Checkbox value={item} />
                                </div>
                              }
                            />
                          </List.Item>
                        )}
                      </VirtualList>
                    </List>
                  </Checkbox.Group>
                </Col>
              </Row>
              <div className='mt-medium' style={{display: "flex"}}>
                <Button className='bit-button hs' type="primary" onClick={() => handleCancel()}>Volver</Button>
                <Button 
                  className={`bit-button hs ml-medium`} 
                  onClick={() => searchBy()} 
                  type="primary"
                  // disabled={checked.length > 0 ? false : true}
                >Continuar</Button>
              </div>
            </div>
          </div>
      </Modal>
    </div>
  );
};

export default ChoosePlayerComponent;
