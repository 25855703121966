import React, { useState, useEffect } from "react";
import { Typography, Row, Col, List, Button, Select } from 'antd';
import VirtualList from 'rc-virtual-list';
import { useNavigate } from 'react-router-dom';
import { AiFillQuestionCircle, AiOutlineMail } from "react-icons/ai";
import { IoIosTime } from "react-icons/io";
import { ImUsers } from "react-icons/im";
import { FaUsers } from "react-icons/fa";
import { BsFillPatchCheckFill, BsBagFill } from "react-icons/bs";

import { getPlayersAction, getDepartmentAction } from '../../components/actions/Player';
import { getTopicsAction } from '../../components/actions/Questions';
import { getCompetitionList } from '../../components/actions/Game';
import { formartTime } from '../../function';

const { Text, Link } = Typography;
const ContainerHeight = 400;

const SearchComponent = (props) => {
  const navigate = useNavigate();

  const [competitions, setCompetitions] = useState([]);
  const [doSearch, setDoSearch] = useState(0);
  const [games, setGames] = useState([]);
  const [initData, setInitData] = useState([]);
  const [mount, setMount] = useState(0);
  const [multiSearchText, setMultiSearchText] = useState('');
  const [players, setPlayers] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);

  const handleCancelModal = props.handleCancel;
  const mode = props.mode;
  const searchText = props.searchText;
  const selectedCompetition = props.selectedCompetition;
  const selectedGame = props.selectedGame;
  const selectedUser = props.selectedUser;
  const setSearchText = props.setSearchText;
  const setSelectedCompetition = props.setSelectedCompetition;
  const setSelectedGame = props.setSelectedGame;
  const setSelectedUser = props.setSelectedUser;

  useEffect(() => {
    if (!mount) {
      setMount(1);
      // getPlayersAction((users) => {
      //   let userlist = users.map((user) => {
      //     return {
      //       title: user.profile.firstName +' '+user.profile.lastName,
      //       email: user.email,
      //       department: user.department,
      //       human: user.password ? true : false,
      //       id: user.id,
      //     }
      //   });
      //   setPlayers(userlist);
      // });
      getTopicsAction((list) => {
        let topicList = list.map((item, index) => {
          return {
            key: index,
            title: item.name,
            questionCnt: item.questCnt,
            time: 0,
            id: item._id,
          }
        });
        setGames(topicList);
        setInitData(topicList);
      })
      getCompetitionList({}, (list) => {
        let competitions = list.map((item, index) => {
          return {
            key: index,
            title: item.name,
            player: item.totalPlayers,
            team: 2,
            question: 14,
            topicId: 20,
            id: item._id,
            game:item.game[0].name
          }
        });
        setCompetitions(competitions);
      })
      // getDepartmentAction((list) => {
      //   let departs = []
      //   list.map((l) => {
      //     departs.push({value: l.name, label: l.name});
      //   })
      //   setSelectOptions(departs);
      // })
    }
  });

  useEffect(() => {
    let data = [];
    if (mode === 'game') data = games;
    if (mode === 'skill') data = competitions;
    // if (mode === 'player') data = players;

    let firstFiltered = [];
    // data.map((d) => {
    //   if (d.title.includes(searchText)) {
    //     firstFiltered.push(d);
    //   }
    // });

    let secondFiltered = [];
    // if (multiSearchText != '') {
    //   // let searchItems = multiSearchText.split(',');
    //   firstFiltered.map((d) => {
    //     if (multiSearchText.includes(d.department)) {
    //       secondFiltered.push(d);
    //     }
    //   });
    // } else {
    //   secondFiltered = firstFiltered;
    // }
    setInitData(secondFiltered);
  }, [mode, searchText, doSearch]);

  useEffect(() => {
    setSearchText('');
  }, [mode])

  const handleChange = (value) => {
    setMultiSearchText(value);
    if (doSearch == 1) setDoSearch(0);
    if (doSearch == 0) setDoSearch(1);
  };

  return (
    <div className="mt-medium">
                <List>
            {
              mode === 'game' ?
                <VirtualList
                  data={games}
                  height={ContainerHeight}
                >
                  {(item) => (
                    <List.Item 
                      key={item.id} 
                      onClick={() => {setSelectedGame(item); setSelectedUser({}); setSelectedCompetition({})}}
                      className={selectedGame.id === item.id ? "list-selected" : ""}
                    >
                      <List.Item.Meta
                        className={selectedGame.id === item.id ? "title-selected" : ""}
                        title={item.title}
                        description={<Row>
                          <Col span={8} className={selectedGame.id === item.id ? "list-item-title color-w" : "list-item-title"}>
                            <AiFillQuestionCircle className={selectedGame.id === item.id ? "list-item-title color-w" : ""}/> {" " + item.questionCnt + " preguntas" } 
                          </Col>
                          <Col span={8} className={selectedGame.id === item.id ? "list-item-title color-w" : "list-item-title"}>
                            <IoIosTime className={selectedGame.id === item.id ? "list-item-title color-w" : ""}/> {" " + formartTime(item.time) + " " } 
                          </Col>
                        </Row>}
                      />
                    </List.Item>
                  )}
                </VirtualList>
                :
                <></>
            }
            {
              mode === 'skill' ?
                <VirtualList
                  data={competitions}
                  height={ContainerHeight}
                >
                  {(item) => (
                    <List.Item 
                      key={item.id}
                      onClick={() => {setSelectedCompetition(item); setSelectedUser({}); setSelectedGame({});}}
                      className={selectedCompetition.id === item.id ? "list-selected" : ""}
                    >
                      <List.Item.Meta
                        className={selectedCompetition.id === item.id ? "title-selected" : ""}
                        title={item.title}
                        description={<Row>
                          <Col span={8} className={selectedCompetition.id === item.id ? "list-item-title color-w" : "list-item-title"}>
                            <ImUsers className={selectedCompetition.id === item.id ? "list-item-title color-w" : ""}/> {" " + item.player + " jugadores" } 
                          </Col>
                          <Col span={8} className={selectedCompetition.id === item.id ? "list-item-title color-w" : "list-item-title"}>
                            <AiFillQuestionCircle className={selectedCompetition.id === item.id ? "list-item-title color-w" : ""}/> {" " + item.question + " preguntas" } 
                          </Col>
                          <Col span={8} className={selectedCompetition.id === item.id ? "list-item-title color-w" : "list-item-title"}>
                            <FaUsers className={selectedCompetition.id === item.id ? "list-item-title color-w" : ""}/> {" " + item.team + " equipos" } 
                          </Col>
                        </Row>}
                      />
                    </List.Item>
                  )}
                </VirtualList>
                :
                <></>
            }
            {/* {
              mode === 'player' ?
                <VirtualList
                  data={initData}
                  height={ContainerHeight}
                >
                  {(item) => (
                    <List.Item 
                      key={item.id}
                      onClick={() => {setSelectedUser(item); setSelectedCompetition({}); setSelectedGame({});}}
                      className={selectedUser.id === item.id ? "list-selected" : ""}
                    >
                      <List.Item.Meta
                        className={selectedUser.id === item.id ? "title-selected" : ""}
                        title={item.title}
                        description={<Row>
                          <Col span={12} className={selectedUser.id === item.id ? "list-item-title color-w" : "list-item-title"}>
                            <AiOutlineMail className={selectedUser.id === item.id ? "list-item-title color-w" : ""}/> {" " + item.email + " " } 
                          </Col>
                          <Col span={12} className={selectedUser.id === item.id ? "list-item-title color-w" : "list-item-title"}>
                            <BsBagFill className={selectedUser.id === item.id ? "list-item-title color-w" : ""}/> { item.department ? item.department : "" } 
                          </Col>
                        </Row>}
                      />
                    </List.Item>
                  )}
                </VirtualList>
                :
                <></>
            } */}
          </List>
    </div>
  );
};

export default SearchComponent;
