//export const SERVER_URL = 'https://beewise-server-app-hj2li.ondigitalocean.app/api/'
// export const SERVER_URL = 'http://localhost:8080/api/'  
// export const SERVER_URL = 'http://localhost:6090/'

console.log('====process.env.REACT_APP_URL_API :', process.env );
export const SERVER_URL = 'https://api.beewise.cl/'

export const GAME_MODAL_STEP = {
    VERIFY: 1,
    CHOOSE_PLAYER: 2,
    CHOOSE_TEAM: 3,
    AUTO_SET_TEAM: 4,
    SET_TEAM: 5,
    CHOOSE_DATE: 6,
    GAME_COMPLETE: 7,
    CREATE_SUCCESS: 8,
}

export const DEPARTMENT = [
    "Operaciones",
    "Marketing",
    "Diseño",   
    "Ventas",
    "RRHH",
    "Otro",
]