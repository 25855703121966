import React, {useEffect, useState} from "react";
import { 
  Table, 
  Typography,
  Input,
  Button,
  Spin,
  Modal,
} from 'antd';
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

import { 
  addDepartmentAction, 
  getDepartmentsAction,
  delDepartmentAction,
  editDepartmentAction,
} from '../../components/actions/Game';

const { Text, Link } = Typography;
const { TextArea } = Input;

const DepartmentComponent = (props) => {
  const [listState, setListState] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [departmentTitle, setDepartmentTitle] = useState('');

  const [editId, setEditId] = useState(-1);
  const [editDepartment, setEditDepartment] = useState('');
  const [addLoading, setAddLoading] = useState(0);

  const [delId, setDelId] = useState(-1);
  const [deleteLoading, setDeleteLoading] = useState(0);

  useEffect(() => {
   loadDepartments();
  })
  const loadDepartments = async () => {
    if (!listState) {
      setListState(1);
      getDepartmentsAction((list) => {
        let departmentList = []
        list.map((l, index) => {
          departmentList.push({
            key: l._id,
            department: l.name
          });
        });
        setDepartments(departmentList);
      });
    }
  }

  const deleteDepart = () => {
    setDeleteLoading(1);
    delDepartmentAction(delId, () => {
      setDeleteLoading(0);
      setDelId(-1);
      setListState(0);
      loadDepartments();
      handleCancelDelete();
    }, () => {
      setDeleteLoading(0);
      setDelId(-1);
      handleCancelDelete();
    })
  }

  const edit = (id, department) => {
    setEditId(id);
    setEditDepartment(department);
  }

  const editComplete = () => {
    editDepartmentAction(
      {id: editId, name: editDepartment}, 
      () => {
        setEditId(-1);
        setListState(0);
        loadDepartments();
      }
    )
  }

  const departmentColumn = [
    {
      title: 'Departamentos',
      dataIndex: 'department',
      key: 'department',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      },
      render: (deparment, record) => (
        editId === record.key ?
          <Input className='hm' value={editDepartment} onChange={(e) => setEditDepartment(e.target.value)}/>
          :
          deparment
      )
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className='v-around h-center'>
          {
            editId === record.key ?
              <AiOutlineCheckCircle 
                onClick={() => editComplete()}
                style={{color: "#38DF2A",fontSize: '20px'}} 
              />
              :
              <FiEdit 
                style={{color: "#8146ff", fontSize: "20px"}} 
                onClick={() => edit(record.key, record.department)}
              />
          }
          <RiDeleteBin6Line 
            style={{color: "#EB5757", fontSize: "20px"}} 
            onClick={() => deleteModal(record.key)}
          />
        </div>
      )
    },
  ];

  const deleteModal = (key) => {
      setDelId(key);
      showModalDelete();
  }
  
  const [openDelete, setOpenDelete] = useState(false);
  const showModalDelete = () => {
      setOpenDelete(true);
  };
  const handleOkDelete = (e) => {
      console.log(e);
      setOpenDelete(false);
  };
  
  const handleCancelDelete = (e) => {
      console.log(e);
      setOpenDelete(false);
  };

  const addDepartment = () => {
    setAddLoading(1);
    addDepartmentAction(
      {name: departmentTitle},
      () => {
        setListState(0);
        setDepartmentTitle('');
        loadDepartments();
        setAddLoading(0);
      },
      () => {setAddLoading(0)}
    );
  }
  return (
    <>
    <div className='pv-large'>
      <Text className='medium-title color-black'>
        Desde aquí podrás agregar los distintos Departamentos de los jugadores, esto te podrá permitir compartir y filtrar de mejor manera a los participantes en cada juego. 
      </Text>
    </div>
    <div className='pv-large mt-medium flex-column h-center'>
      <div className='v-around mt-medium' style={{width: "70%"}}>
        <Input 
          placeholder="Ingresa un nuevo Departamento"
          onChange={(e) => setDepartmentTitle(e.target.value)}
          className='mt-bit hs'
          value={departmentTitle}
          style={{width: "50%"}}
        />
        <Button 
          className={`bit-button hs mt-bit ${departmentTitle?"":"back-grey"}`}
          disabled={departmentTitle && !addLoading ?false:true}
          onClick={() => addDepartment()} 
          type="primary"
        >
          {
            !addLoading ?
              "Publicar"
              :
              <Spin size='small'/>
          }
        </Button>
      </div>
      <div style={{marginTop: "40px", width: "70%"}}>
        <Table
          columns={departmentColumn}
          dataSource={departments}
          pagination={false}
        />
      </div>
    </div>
        
    <Modal
        title=''
        open={openDelete}
        onOk={handleOkDelete}
        onCancel={handleCancelDelete}
        footer=''
    >
        <div className='modal-content' style={{border: "none"}}>
            
            <img src='/img/icon/warning.png' width="91"/>
            <h3>Eliminar Departarmento</h3>
            <Text className='medium-title text-center'>¿Estás seguro de que quieres  eliminar el Departamento seleccionado?</Text>
            <div>
              <Button className='bit-button hs' onClick={() => handleCancelDelete()} type="primary">Volver</Button>
              <Button className='bit-button hs ml-medium' onClick={() => deleteDepart()} type="primary">
                {
                  deleteLoading == 1 ?
                    <Spin size="small"/>
                    :
                    "Eliminar"
                }
              </Button>
            </div>
        </div>
    </Modal>
    </>
  );
};

export default DepartmentComponent;
