import axios from 'axios';
import {SERVER_URL} from '../../constant';
import api from '../../api/interceptor';
import { isStatusSuccess } from '../../utils/apiUtils';

export const registerAction = async (uploadData, callback, err) => {
    try {
        const response = await api.post('admin/auth/register', uploadData);
        console.log(response);
        isStatusSuccess(response.status) ? callback() : err();
    } catch (error) {
        console.log(error);
        err();
    }
}

export const loginAction = (loginData, callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/auth/login',
        // withCredentials: true,
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : loginData,
    };
    
    axios(config)
    .then(function (res) {
        res.status === 200 ? callback(res.data.admin) : err();
    })
    .catch(function (error) {
        console.log(error);
        err();
    });
}
