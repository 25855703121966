import React, {useEffect, useState} from "react";
import { 
  Table, 
  Typography,
  Input,
  Button,
  Spin,
  Modal,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

import { 
  addCategoryAction, 
  getCategoryAction,
  delCategoryAction,
  editCategoryAction,
} from '../actions/Game';

const { Text, Link } = Typography;
const { TextArea } = Input;

const CategoryComponent = (props) => {
  const [listState, setListState] = useState(0);
  const [categoriese, setCategriese] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState('');

  const [editId, setEditId] = useState(-1);
  const [editCategory, setEditCategory] = useState('');
  const [addLoading, setAddLoading] = useState(0);

  const [delId, setDelId] = useState(-1);
  const [deleteLoading, setDeleteLoading] = useState(0);

  useEffect(() => {
    if (!listState) {
      setListState(1);
      getCategoryAction((list) => {
        let categoryList = []
        list.map((l, index) => {
          categoryList.push({
            key: l.id,
            category: l.category
          });
        });
        setCategriese(categoryList);
      });
    }
  })
  const init = () => {
  }

  const deleteCategory = () => {
    delCategoryAction(delId, () => {
      setDeleteLoading(0);
      setDelId(-1);
      setListState(0);
      handleCancelDelete();
    }, () => {
      setDeleteLoading(0);
      setDelId(-1);
      handleCancelDelete();
    })
  }

  const edit = (id, category) => {
    setEditId(id);
    setEditCategory(category);
  }

  const editComplete = () => {
    editCategoryAction(
      {id: editId, category: editCategory}, 
      () => {
        setEditId(-1);
        setListState(0);
      }
    )
  }

  const categoryColumn = [
    {
      title: 'Categorías',
      dataIndex: 'category',
      key: 'category',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      },
      render: (deparment, record) => (
        editId === record.key ?
          <Input className='hm' value={editCategory} onChange={(e) => setEditCategory(e.target.value)}/>
          :
          deparment
      )
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className='v-around h-center'>
          {
            editId === record.key ?
              <AiOutlineCheckCircle 
                onClick={() => editComplete()}
                style={{color: "#38DF2A",fontSize: '20px'}} 
              />
              :
              <FiEdit 
                style={{color: "#8146ff", fontSize: "20px"}} 
                onClick={() => edit(record.key, record.category)}
              />
          }
          <RiDeleteBin6Line 
            style={{color: "#EB5757", fontSize: "20px"}} 
            onClick={() => deleteModal(record.key)}
          />
        </div>
      )
    },
  ];

  const deleteModal = (key) => {
      setDelId(key);
      showModalDelete();
  }
  
  const [openDelete, setOpenDelete] = useState(false);
  const showModalDelete = () => {
      setOpenDelete(true);
  };
  const handleOkDelete = (e) => {
      console.log(e);
      setOpenDelete(false);
  };
  
  const handleCancelDelete = (e) => {
      console.log(e);
      setOpenDelete(false);
  };

  const addCategory = () => {
    setAddLoading(1);
    addCategoryAction(
      {category: categoryTitle},
      () => {
        setListState(0)
        setCategoryTitle('')
        setAddLoading(0);
      },
      () => {
        setAddLoading(0);
      }
    );
  }
  return (
    <>
    <div className='pv-large'>
      <Text className='medium-title color-black'>
        Ingresa las Categorías acorde a los temas que se van a aprender, recuerda que estos datos te ayudarán a complementar tus juegos y optimizar la visualización de los resultados.
      </Text>
    </div>
    <div className='pv-large mt-medium flex-column h-center'>
      <div className='v-around mt-medium' style={{width: "70%"}}>
        <Input 
          placeholder="Ingresa una nueva Categoría"
          onChange={(e) => setCategoryTitle(e.target.value)}
          className='mt-bit hs'
          value={categoryTitle}
          style={{width: "50%"}}
        />
        <Button 
          className={`bit-button hs mt-bit ${categoryTitle?"":"back-grey"}`}
          disabled={categoryTitle && !addLoading?false:true}
          onClick={() => addCategory()} 
          type="primary"
        >
          {
            !addLoading ?
              "Añadir Categoría"
              :
              <Spin size='small'/>
          }
        </Button>
      </div>
      <div style={{marginTop: "40px", width: "70%"}}>
        <Table
          columns={categoryColumn}
          dataSource={categoriese}
          pagination={false}
        />
      </div>
    </div>
        
    <Modal
        title=''
        open={openDelete}
        onOk={handleOkDelete}
        onCancel={handleCancelDelete}
        footer=''
    >
        <div className='modal-content' style={{border: "none"}}>
            
            <img src='/img/icon/warning.png' width="91"/>
            <h3>Eliminar Categoría</h3>
            <Text className='medium-title text-center'>¿Estás seguro de que quieres  eliminar la Categoría seleccionada?</Text>
            <div>
              <Button className='bit-button hs' onClick={() => handleCancelDelete()} type="primary">Volver</Button>
              <Button className='bit-button hs ml-medium' onClick={() => deleteCategory()} type="primary">
                {
                  deleteLoading == 1 ?
                    <Spin size="small"/>
                    :
                    "Eliminar"
                }
              </Button>
            </div>
        </div>
    </Modal>
    </>
  );
};

export default CategoryComponent;
