import { Button, Modal, Input, Typography, Collapse, Table, Spin, Col, Row } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { FiEdit } from "react-icons/fi";
import { RiGitBranchFill } from "react-icons/ri";
import { FaPause, FaPlay } from "react-icons/fa";
import { BsFillShareFill } from "react-icons/bs";
import ReactInputVerificationCode from "react-input-verification-code";
import styled from "styled-components";

import InfoModal from "../../components/player/InfoModal";
import CodeModal from "../../components/player/CodeModal";

import { 
  addAdminAction, 
  getAdminsAction, 
  removeAdminsAction, 
  editAdminAction, 
  sendInviteCode,
  changeStateAdminAction 
} from '../../components/actions/Player';
import { formatAMPM } from '../../function';
import '../../scss/dashboard.scss';

const { Text, Title, Link } = Typography;
const { Search } = Input;
const { Panel } = Collapse;


const StyledReactInputVerificationCode = styled.div`
       display: flex;
       justify-content: center;

       --ReactInputVerificationCode-itemWidth: 40px;
       --ReactInputVerificationCode-itemHeight: 40px;
       --ReactInputVerificationCode-itemSpacing: 8px;

       .ReactInputVerificationCode__item {
           font-size: 16px;
           font-weight: 500;
           color: #ABB4C5;
           border: 1px solid
           ${({ isInvalid }) => (isInvalid ? "#EF6C65" : "rgba(28, 30, 60, 0.4)")};
           border-radius: 4px;
           line-height: 40px;
           box-shadow: none;
           background: #fff;
       }

       .ReactInputVerificationCode__item.is-active {
           box-shadow: none;
           border: 1px solid #36c6d9;
       }
       `;

export default function AdministratorPage() {
  const navigate = useNavigate();
  const [isSubClient, setIsSubClient] = useState(false);
  const [modalTab, setModalTab] = useState(1);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [profileType, setProfileType] = useState('');
  const [adminList, setAdminList] = useState([]);
  const [showAdminList, setShowAdminList] = useState([]);
  const [listState, setListState] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [verifyCode, setVerifiCode] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [username, setUserName] = useState('');
  const [editId, setEditId] = useState(-1);
  const [addLoading, setAddLoading] = useState(0);
  const [changeStateId, setChangeStateId] = useState(-1);
  const [changeStateUserName, setChangeStateUserName] = useState('');
  const [changeStateVal, setChangeStateVal] = useState(-1);
  const [changeStateLoading, setChangeStateLoading] = useState(0);
  const [sendInviteLoading, setSendInviteLoading] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(0);
  const [searchText, setSearchText] = useState('');

  const onSearch = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    let showList = [];
    for (let i in adminList) {
      let p = adminList[i];
      if (p.firstName.includes(searchText) || p.lastName.includes(searchText)) {
        showList.push(p);
      }
    }
    setShowAdminList(showList);
  }, [adminList, searchText]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let selected = selectedRows.map(row => {
        return row.key;
      });
      setSelectedIds(selected);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const init = () => {
    setName('');
    setSurname('');
    setEmail('');
    setProfileType('');
  }

  useEffect(() => {
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    setUserName(loginInfo.firstName +' '+ loginInfo.lastName);
    if (!listState) {
      getAdminsAction(list => {
        let data = [];
        let _type = '';
        list.map((l, index) => {
          if(l.rol === 'CLIENT_ADMIN'){
            _type = 'Sub Administrador'
          }else if(l.rol === 'CLIENT_SUPER_ADMIN'){
            _type = 'Administrador'
          }
          data.push({
            key: l._id,
            user: l.firstName +" "+ l.lastName,
            firstName: l.firstName,
            lastName: l.lastName,
            email: l.email,
            code: l.inviteCode,
            profileType: l.rol,
            state: l.isActive,
            type: _type,
            action: '',
          });
        });console.log(data);
        setAdminList(data);
        setListState(1);
        const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
        setUserName(loginInfo.firstName +' '+ loginInfo.lastName);
        setIsSubClient(loginInfo.isSubClient);
      });
    }
    var event = new Date();
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    setDate(event.toLocaleDateString('es-ES', options));
    setTime(formatAMPM(event));
  });

  const competationColumn = [
    {
      title: 'Usuario',
      dataIndex: 'user',
      key: 'user',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Tipo de usuario',
      dataIndex: 'type',
      key: 'type',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className='v-around h-center'>
          <FiEdit style={{color: "#8146ff", fontSize: "20px"}} onClick={() => showEditModal(record)}/>
          <BsFillShareFill onClick={() => showModalInvite({toAddress: record.email, text: record.code})} style={{color: "#8146ff", fontSize: "20px"}}/>
          {
            record.state === true ?
              <FaPause onClick={() => changeState(record.key)} style={{color: "#f26e6e", fontSize: "20px"}}/>
              :
              <FaPlay onClick={() => changeState(record.key)} style={{color: "#56c456", fontSize: "18px"}}/>
          }
        </div>
      )
    },
  ];

  const showEditModal = (record) => {
      setEditId(record.key);
      setName(record.firstName);
      setSurname(record.lastName);
      setEmail(record.email);
      setProfileType(record.profileType);
      setOpen(true);
  }

  const [open, setOpen] = useState(false);
  const showModal = () => {
      init();
      setEditId(-1);
      setOpen(true);
  };
  const handleOk = (e) => {
      console.log(e);
      setOpen(false);
  };
  
  const handleCancel = (e) => {
      console.log(e);
      setOpen(false);
  };
  
  const [open1, setOpen1] = useState(false);
  const showModal1 = () => {
      setOpen1(true);
  };
  const handleOk1 = (e) => {
      console.log(e);
      setOpen1(false);
  };
  
  const handleCancel1 = (e) => {
      console.log(e);
      setOpen1(false);
  };

  const action = () => {
    editId < 0 ?
      addAdmin()
      :
      editAdmin()
  }

  const addAdmin = e => {
    setAddLoading(1);
    addAdminAction({
      firstName: name,
      lastName: surname,
      email: email,
      profileType: profileType,
      inviteCode: verifyCode
    }, data => {
      // sendInvite({toAddress: email, text: verifyCode});
      setAddLoading(0);
      setListState(0);
      setVerifiCode(data.inviteCode);
      setModalTab(2);
      // handleCancel();
    }, () => {setAddLoading(0)});
  }

  const addNewAdmin = () =>{
    setAddLoading(1);
    const addData = {
      firstName: name,
      lastName: surname,
      email: email,
      profileType: profileType
    };
    addAdminAction(
      addData, (data) => {
        console.log("LA DATA ====", data);
        setAddLoading(0);
        setListState(0);
        setVerifiCode(data.inviteCode);
        setModalTab(2);
      }, () =>{
        console.log('failure');
        setAddLoading(0);
        setModalTab(1);
      }
    )
  }

  const editAdmin = e => {
    setAddLoading(1);
    editAdminAction(editId, {
      firstName: name,
      lastName: surname,
      email: email,
      profileType: profileType,
      inviteCode: verifyCode
    }, data => {
      setEditId(-1);
      setListState(0);
      setModalTab(1); 
      handleCancel();
      setAddLoading(0);
    }, () => {setAddLoading(0)});
  }

  // const removeAdmin = () => {
  //   removeAdminsAction(selectedIds, () => {
  //     setListState(0);
  //   });
  // }

  

  const removeAdmin = () => {
    showModalDelete();
  }

  const removePlayerConfirm = () => {
    setDeleteLoading(1);
    removeAdminsAction(selectedIds, () => {
      setListState(0);
      handleCancelDelete();
      setDeleteLoading(0);
    }, () => {
      handleCancelDelete();
      setDeleteLoading(0);
    });
  }

  const sendInvite = (inviteData) => {
    setSendInviteLoading(1);
    sendInviteCode(inviteData, () => {
      handleCancelInvite();
      setSendInviteLoading(0);
    }, () => {
      handleCancelInvite();
      setSendInviteLoading(0);
    });
  }

  const changeState = (id) => {
    setChangeStateId(id);
    for (let i in adminList) {
      let p = adminList[i];
      if (p.key == id) {
        setChangeStateUserName(p.firstName);
        setChangeStateVal(p.state);
      }
    }
    showModal1();
    // changeStateAdminAction(id, () => {
    //   setListState(0);
    // });
  }

  const changeStateConfirm = () => {
    setChangeStateLoading(1);
    changeStateAdminAction(changeStateId, 
      () => {
        setListState(0);
        handleCancel1();
        setChangeStateId(-1);
        setChangeStateLoading(0);
      }, () => {
        handleCancel1();
        setChangeStateId(-1);
        setChangeStateLoading(0);
      });
  }

  const [openInvite, setOpenInvite] = useState(false);
  const [inviteCodeModal, setInviteCodeModal] = useState('');
  const [inviteData, setInviteData] = useState('');
  const showModalInvite = (data) => {
    setInviteCodeModal(data.text);
    setInviteData(data);
    setOpenInvite(true);
    console.log(data);
  };
  const handleOkInvite = (e) => {
    setOpenInvite(false);
  };
  
  const handleCancelInvite = (e) => {
    setOpenInvite(false);
  };
  
  const [openDelete, setOpenDelete] = useState(false);
  const showModalDelete = () => {
      setOpenDelete(true);
  };
  const handleOkDelete = (e) => {
      console.log(e);
      setOpenDelete(false);
  };
  
  const handleCancelDelete = (e) => {
      console.log(e);
      setOpenDelete(false);
      window.location.reload();
  };
  
  return (
    <div className='dashboard'>
        <div className='panel borderL flex-column h-center gap-bit b-shadow'>
            <div className='v-between  w-100 h-end'>
                <Title className='large-title no-margin'>Hola {username}</Title>
                <Text className='smail-title'>{date}</Text>
            </div>
            <div className='v-between  w-100'>
                <Text className='bit-title'>Aquí podras añadir y administrar los perfiles de los administradores que agregues.</Text>
                <Text className='bit-title'>{time}</Text>
            </div>
        </div>
        <div className='panel borderL mt-medium b-shadow'>
            <div className='v-between'>
                <Title className='large-title no-margin bold'>Administradores</Title>
                <div className='v-end h-center'>
                  <Search
                      placeholder="Buscar usuario"
                      allowClear
                      onSearch={onSearch}
                      style={{
                          width: "30%",
                      }}
                  />
                    <Button className={`bit-button hs ml-bit`} type="primary" onClick={() => removeAdmin()}>
                      Eliminar
                    </Button>
                    <Button className={`bit-button hs ml-bit`}  type="primary" onClick={() => showModal()}>
                      Nuevo Administrador
                    </Button>
                </div>
            </div>
            <div className='mt-large'>
              <Table
                columns={competationColumn}
                dataSource={showAdminList}
                pagination={false}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            </div>
        </div>
        <Modal
            title={modalTab == 1 ? (editId > 0 ? "Editar Administrador" : "Ingreso nuevo Administrador") : 'Ingreso nuevo usuario'}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            width="70%"
            // height="60%"
            style={{
              paddingBottom: "0px",
            }}
            footer=''
            className='business-modal'
        >
            <div className='modal-content flex-column v-between h-center' style={{border: "none", height: "100%"}}>
                <div className='w-100'>
                  {modalTab == 1 ? 
                    <InfoModal 
                      type="admin" 
                      setName={setName} 
                      setSurname={setSurname} 
                      setEmail={setEmail} 
                      setProfileType={setProfileType}
                      name={name}
                      surname={surname}
                      email={email}
                      profileType={profileType}
                    /> 
                    : 
                    <CodeModal type="admin" setVerify={verifyCode}/>
                  }
                </div>
                {modalTab == 1 ? 
                  <div className='w-100 v-center mt-large'>
                    <Button className='bit-button hs' onClick={() => handleCancel()} type="primary">Volver</Button>
                    <Button className='bit-button hs ml-medium' onClick={() => addNewAdmin()} type="primary">Agregar</Button>
                  </div>
                  : 
                  <div className='w-100 v-center mt-large'>
                    <Button className='bit-button hs' 
                      onClick={() => {handleCancel()}} 
                      type="primary"
                      disabled={addLoading ? true : false}
                    >
                      {
                        !addLoading ? 
                          "Finalizar"
                          :
                          <Spin size="small"/>
                      }
                    </Button>
                  </div>
                }
            </div>
        </Modal>
        
        <Modal
            title=''
            open={open1}
            onOk={handleOk1}
            onCancel={handleCancel1}
            footer=''
        >
            <div className='modal-content' style={{border: "none"}}>
                {
                  changeStateVal === false ?
                    <img src='/img/icon/active.png' width="91"/>
                    :
                    <img src='/img/icon/warning.png' width="91"/>
                }
                {
                  changeStateVal === false ?
                    <h3>Reactivar Administrador</h3>
                    :
                    <h3>Pausar Administrador</h3>
                }
                {
                  changeStateVal === false ?
                    <Text className='medium-title text-center'>¿Estás seguro de que quieres  reactivar la actividad de {changeStateUserName}?</Text>
                    :
                    <Text className='medium-title text-center'>¿Estas seguro de que quieres  pausar la actividad de {changeStateUserName}?</Text>
                }
                {
                  changeStateVal === false ?
                    ""
                    :
                    <p>Esto significa que el administrador no podrá lanzar ninguna competencia, como tampoco agregar nuevos jugadores</p>
                }
                
                <div>
                  <Button className='bit-button hs' type="primary" onClick={() => handleCancel1()}>Volver</Button>
                  <Button className='bit-button hs ml-medium' onClick={() => changeStateConfirm()} type="primary">
                    {
                      changeStateLoading == 1 ?
                        <Spin size="small"/>
                        :
                        (
                          changeStateVal === false ?
                            "Activar" 
                            :
                            "Pausar"
                        )
                    }
                  </Button>
                </div>
            </div>
        </Modal>
        
        <Modal
            title=''
            open={openInvite}
            onOk={handleOkInvite}
            onCancel={handleCancelInvite}
            footer=''
        >
            <div className="v-center padding-medium" >
              <div className="flex-column h-center" style={{width: "70%"}}>
                <Text className="large-title color-b mt-medium">Compartir código por email</Text>    
                <StyledReactInputVerificationCode className="mt-bit mt-bit mt-medium">
                    <ReactInputVerificationCode length={5} placeholder={0} type='string' value={inviteCodeModal}/>
                </StyledReactInputVerificationCode>
                <Text className="medium-title mt-bit text-center mt-medium">Enviaremos el codigo de acceso al correo añadido del usuario</Text>
                <div className='mt-medium' style={{display: "flex"}}>
                  <Button className='bit-button hs' type="primary" onClick={() => handleCancelInvite()}>Volver</Button>
                  <Button className='bit-button hs ml-medium' onClick={() => sendInvite(inviteData)} type="primary">
                    {
                      sendInviteLoading == 0 ?
                        "Enviar Correo"
                        :
                        <Spin size="small"/>
                    }
                  </Button>
                </div>
              </div>
            </div>
        </Modal>
        
        <Modal
            title=''
            open={openDelete}
            onOk={handleOkDelete}
            onCancel={handleCancelDelete}
            footer=''
        >
            <div className='modal-content' style={{border: "none"}}>
                
                <img src='/img/icon/warning.png' width="91"/>
                <h3>Eliminar Administrador</h3>
                <Text className='medium-title text-center'>¿Estas seguro de que quieres  eliminar?</Text>
                <div>
                  <Button className='bit-button hs' onClick={() => handleCancelDelete()} type="primary">Volver</Button>
                  <Button className='bit-button hs ml-medium' onClick={() => removePlayerConfirm()} type="primary">
                    {
                      deleteLoading == 1 ?
                        <Spin size="small"/>
                        :
                        "Eliminar"
                    }
                  </Button>
                </div>
            </div>
        </Modal>
    </div>
  );
}
