import React, { useState, useEffect } from "react";
import { Typography } from 'antd';
import { Button , Col, Row, Popover, Select, TimePicker } from 'antd';
import { RiErrorWarningFill } from "react-icons/ri";
import Calendar from 'moedim';
import Moedim from 'moedim';
import moment from 'moment';

import { GAME_MODAL_STEP } from "../../constant";
import dayjs from 'dayjs';

const format = 'HH:mm';
const { Text, Link } = Typography;
const { Option } = Select;

const content = (
  <div className='flex-column v-center padding-bit' style={{width: "450px"}}>
    <Text className="small-title text-center"> Cuando existen diferencias significativas entre la cantidad de jugadores por equipo, recomendamos utilizar la opción <Text className="bold">Ponderado</Text>, que significa que el puntaje que agrega cada jugador "a su equipo" dependerá de la relación entre la cantidad de jugadores por equipo, esto con la finalidad de normalizar la competencia entre los mismos.</Text>
    <br/>
    <Text className="small-title text-center"> Por ejemplo, si hay dos equipos, un equipo A que tiene 10 jugadores y otro B que tiene 5 jugadores, entonces los jugadores del equipo A sumarán la mitad de puntos que los jugadores del equipo B por cada respuesta correcta.</Text>
    <br/>
    <Text className="small-title text-center"> Cuando seleccionamos la opción <Text className="bold">No Ponderado</Text>, cada jugador suma el mismo puntaje "a su equipo" por cada respuesta correcta.</Text>
    <br/>
    <Text className="text-center"> Notar que Ponderado y No Ponderado no afectan el puntaje de cada jugador en forma individual.</Text>
  </div>
);

const ChooseDateComponent = (props) => {
  const convertDateTime = (date, time) => {
    let convertDate = moment(date).format("YYYY-MM-DD");
    let appendDate = convertDate +' '+ time;
    return new Date(appendDate);
  }

  const next = props.next;
  const previous = props.previous;
  const startDate = props.startDate;
  const setStartDate = props.setStartDate;
  const termDate = props.termDate;
  const setTermDate = props.setTermDate;
  const ponderatoTab = props.ponderatoTab;
  const setPonderato = props.setPonderato;
  const [startTime, setStartTime] = useState('00:00');
  const [termTime, setTermTime] = useState('23:59');
  
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      // setSelectedPlayer(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  
  const handleTeamChange = (value) => {
    console.log(value);
  };

  return (
    <>
      <div className="auto-set-panel"
        style={{
          width: '85%',
          height: "80%"
        }}
      >
        <div style={{
          background: "#F2F2FF",
          border: "1px solid #E8E8FF",
          borderRadius: "10px",
          height: "61px",
          display:"none"
        }}>
          {/* <Row  className="pv-small">
            <Col span={12}>
              <Text
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "#142748",
                  lineHeight: "61px"
                }}
              >
                ¿Cómo te gustaría visualizar los resultados?
              </Text>
            </Col>
            <Col span={12} style={{height: "61px",display: "flex", justifyContent: "end", alignItems: "center"}}>
              <div>
                <div className='padding-bit back-grey pv-small ph-bit borderS' style={{display: "table", width: "388px"}}>
                  <Button className={ponderatoTab == 1 ? `small-button color-black-button-s back-grey hm` : `small-button color-black-button-s hm`} disabled={ponderatoTab != 1 ? true : false} onClick={() => setPonderato(2)} type="primary">
                    Ponderado
                  </Button>
                  <Button className={ponderatoTab != 1 ? `small-button color-black-button-s back-grey hm` : `small-button color-black-button-s hm`} disabled={ponderatoTab == 1 ? true : false} onClick={() => setPonderato(1)} type="primary">
                    No Ponderado
                  </Button>
                </div>
              </div>
              <Popover content={content} placement="bottomRight" trigger="click">
                <RiErrorWarningFill style={{fontSize: "30px", color: "#5E5BC0", lineHeight: "61px", marginLeft: "6px"}} />
              </Popover>
            </Col>
          </Row> */}
        </div>
        <Text
            style={{
              fontFamily: 'Poppins',
              fontWeight: 600,
              fontSize: "16px",
              color: "#142748",
              lineHeight: "61px"
            }}
          >Selecciona la fecha de tu competencia</Text>
        <div className="choose-date v-around h-center" >
          <div>
            <Text
              style={{
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: "16px",
                color: "#191635",
                lineHeight: "21px"
              }}
            >Fecha inicio</Text>
            <Calendar value={startDate} 
              onChange={(date) => {
                setStartDate(convertDateTime(date, startTime));
              }}
              locale="es-ES"
              className="date-calendar" 
            />
            <div className="v-center">
              <Text
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#747B7B",
                  lineHeight: "21px",
                  alignSelf:"center",
                  marginRight:"20px"
                }}
              >Hora inicio </Text>
              <TimePicker defaultValue={dayjs(moment(convertDateTime(new Date(), startTime)), format)} 
                size="large"
                onChange={(time, timeString) => {
                  setStartTime(timeString)
                  setStartDate(convertDateTime(startDate, timeString));
                }} 
                format={format} 
              />
            </div>
          </div>
          <div>
            <Text
              style={{
                fontFamily: 'Poppins',
                fontWeight: 600,
                fontSize: "16px",
                color: "#191635",
                lineHeight: "21px"
              }}
            >Fecha término</Text>
            <Calendar value={termDate} 
              onChange={(date) => {
                if (startDate > convertDateTime(date, termTime)) {
                  return;
                }
                setTermDate(convertDateTime(date, termTime));
              }} 
              locale="es-ES"
              className="date-calendar" 
            />
            <div className="v-center">
            <Text
                style={{
                  fontFamily: 'Poppins',
                  fontWeight: 600,
                  fontSize: "16px",
                  color: "#747B7B",
                  lineHeight: "21px",
                  alignSelf:"center",
                  marginRight:"20px"
                }}
              >Hora término </Text>
              <TimePicker defaultValue={dayjs(moment(convertDateTime(new Date(), termTime)), format)} 
                size="large"
                onChange={(time, timeString) => {
                  if (startDate > convertDateTime(termDate, timeString)) {
                    return;
                  }
                  setTermTime(timeString)
                  setTermDate(convertDateTime(termDate, timeString))
                }} 
                format={format} 
            />
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 v-between" style={{paddingLeft: "10%", paddingRight: "10%"}}>
        <Button className="small-button hm" onClick={() => next(GAME_MODAL_STEP.CHOOSE_TEAM)}>Volver</Button>
        <Button 
          className="small-button hm"  
          onClick={() => next(GAME_MODAL_STEP.GAME_COMPLETE)}
          disabled={new Date(startDate) > new Date(termDate) ? true: false}
        >Continuar</Button>
      </div>
    </>
  );
};

export default ChooseDateComponent;
