import React, { useEffect, useState } from "react";
import { Typography } from 'antd';
import { Button , Col, Row, Table, Select, Modal, List, Checkbox } from 'antd';
import VirtualList from 'rc-virtual-list';
import { FiFilter } from "react-icons/fi";
import { BsArrowRightCircle } from "react-icons/bs";

import { GAME_MODAL_STEP } from "../../constant";
import { getDepartmentAction } from '../../components/actions/Player';

const { Text, Link } = Typography;
const { Option } = Select;

const SetTeamComponent = (props) => {
  const next = props.next;
  const previous = props.previous;
  const selectedPlayer = props.selectedPlayer;
  const teamCnt = props.teamCnt;
  const setTeams = props.setTeams;
  const teams = props.teams;
  const [mountState, setMountState] = useState(0);
  const [listState, setlistState] = useState(0);
  const [playerList, setPlayerList] = useState([]);
  const [selectElement, setSelectElement] = useState([]);
  const [vteams, setVteams] = useState([]);
  const [departmentInputState, setDepartmentInputState] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    if (!mountState) {
      setMountState(1);
      let element = [];
      setPlayerList(selectedPlayer);
      for (let i = 0; i < teamCnt; i++) {
        element.push(<Option value={i+1}>{i+1}</Option>);
      }
      setSelectElement(element);

      getDepartmentAction((list) => {
        let departs = []
        list.map((l) => {
          departs.push(l.department);
        })
        departs.push('Otro')
        setDepartmentList(departs);
      })
    }
  });

  const includeTeam = (val, record) => {
    let memberExi = false;
    let nteams = [];
    vteams.map((vt) => {
      if (vt.key == record.key) {
        memberExi = true;
        return;
      }
      nteams.push(vt);
    });
    nteams.push({
      ...record,
      tNumber: val
    });
    setVteams(nteams);
  }

  const onScroll = (e) => {
     console.log();
  };

  const searchBy = () => {
    let players = [];
    if (checked.length == 0 || checked.includes('Otro')) {
      setPlayerList(selectedPlayer);
      handleCancel();
      return;
    }
    selectedPlayer.map((p) => {
      if (checked.includes(p.department)) {
        players.push(p);
      }
    });
    setPlayerList(players);
    handleCancel();
  }

  const goNext = () => {
    if (vteams) {
      let teamStruc = [];
      vteams.map((vt) => {
        if (!teamStruc[vt.tNumber-1]) teamStruc[vt.tNumber-1] = [];
        teamStruc[vt.tNumber-1].push(vt);
      });
      setTeams(teamStruc);
    }
    next(GAME_MODAL_STEP.CHOOSE_DATE)
  }
  
  const tableColumn = [
    {
      title: 'Nombre',
      dataIndex: 'firstName',
      key: 'firstName',
      sort: () => {}
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: "Equipo",
      dataIndex: 'tool',
      key: 'tool',
      render: (tool, record) => (
        <Select
          defaultValue="Seleccione un equipo"
          onChange={(val) => {includeTeam(val, record)}}
        >
          {selectElement}
        </Select>
      )
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      // setSelectedPlayer(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  
  const handleTeamChange = (value) => {
    console.log(value);
  };
  const [open, setOpen] = useState(false);
  // const [inviteCodeModal, setInviteCodeModal] = useState('');
  // const [inviteData, setInviteData] = useState('');
  const showModal = (data) => {
    setOpen(true);
    console.log(data);
  };
  const handleOk = (e) => {
    setOpen(false);
  };
  
  const handleCancel = (e) => {
    setOpen(false);
  };

  return (
    <>
      <div className="set-team"
        style={{
          width: '70%',
          height: "80%"
        }}
      >
        <div className="v-between">
          <Text
            style={{
              fontFamily: 'Poppins',
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "30px",
              color: "#142748"
            }}
          >
            Equipos elegidos:  4
          </Text>
          <Button className="filter-button" onClick={() => showModal()} style={{width: "30%"}}>
            <Text className="small-title">Filtro de Busqueda</Text>
            <FiFilter style={{fontSize: "16px", color: "#6A67CE"}}/>
          </Button>
        </div>
        <Table
          className="mt-bit"
          columns={tableColumn}
          dataSource={playerList}
          pagination={false}
          scroll={{
            y: "100",
          }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
        />
      </div>
      <div className="w-100 v-around">
        <Button className="small-button hm" onClick={() => next(GAME_MODAL_STEP.CHOOSE_TEAM)}>Volver</Button>
        <Button className={`small-button hm ${vteams.length!==0?"":"back-grey"}`} disabled={vteams.length!==0?false:true} onClick={() => goNext()}>Continuar</Button>
      </div>
      <Modal
          title=''
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          footer=''
      >
          <div className="v-center padding-medium" >
            <div className="flex-column h-center" style={{width: "100%"}}>
              <Row className="w-100 v-between">
                <Col span={11}>
                  <List>
                    <VirtualList
                      data={[
                        1
                      ]}
                      height={300}
                      itemHeight={47}
                      itemKey="key"
                      onScroll={onScroll}
                    >
                      {(item) => (
                        <List.Item key="">
                          <List.Item.Meta
                            title={
                              <div className="v-between h-center">
                                Departamento
                                <BsArrowRightCircle style={{color: "#5E5BC0"}}/>
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    </VirtualList>
                  </List>
                </Col>
                <Col span={11}>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    value={checked}
                    onChange={(checkedValues) => {
                      setChecked(checkedValues);
                    }}
                  >
                    <List>
                      <VirtualList
                        data={departmentList}
                        height={300}
                        itemHeight={47}
                        itemKey="key"
                        onScroll={onScroll}
                      >
                        {(item, index) => (
                          <List.Item key="">
                            <List.Item.Meta
                              title={
                                <div className="v-between h-center">
                                  {item}
                                  <Checkbox value={item} />
                                </div>
                              }
                            />
                          </List.Item>
                        )}
                      </VirtualList>
                    </List>
                  </Checkbox.Group>
                </Col>
              </Row>
              <div className='mt-medium' style={{display: "flex"}}>
                <Button className='bit-button hs' type="primary" onClick={() => handleCancel()}>Volver</Button>
                <Button 
                  className={`bit-button hs ml-medium`} 
                  onClick={() => searchBy()} 
                  type="primary"
                  // disabled={checked.length > 0 ? false : true}
                >Continuar</Button>
              </div>
            </div>
          </div>
      </Modal>
    </>
  );
};

export default SetTeamComponent;
