import axios from 'axios';
import qs from 'qs';
import {SERVER_URL} from '../../constant';
import api from '../../api/interceptor';
import { isStatusSuccess } from '../../utils/apiUtils';

export const addCompetitionAction = (addData, callback, err) => {    
    api.post('admin/competition/create', addData)
    .then(function (res) {
        console.log("La res: ",res.status);
        res.status === 200 ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const teamRankingAction = (id, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'get',
        url: SERVER_URL + 'admin/team_ranking/'+id,
        crossdomain: true,
        headers: {
            'x-access-token': loginInfo.accessToken
        }
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const satisticAction = (data, callback, err) => {
    console.log('===data::', data);
    // convertir obj en params url para pasar en el metodo get
    const queryParams = qs.stringify(data);
    api.get(`/admin/dashboard?${queryParams}`)
    .then(function (res) {
        res.status === 200 ? callback(res.data) : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const downloadStaticAction = (data, callback, err) => {
    const queryParams = qs.stringify(data);
    api.get(`/admin/dashboard/xlsx/?${queryParams}`, {
        responseType: 'blob', 
        headers: {'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }})
    .then(function (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'dashboard.xlsx');
        document.body.appendChild(link);
        link.click();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getCompetitionList = (data, callback, err) => {
    api.get('admin/competition/list')
    .then(function (res) {
        res.status === 200 ? callback(res.data.list) : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getChallengeDetailList = (id, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'get',
        url: SERVER_URL + 'admin/challenge_detail_list/'+id,
        crossdomain: true,
        headers: {
            'x-access-token': loginInfo.accessToken
        }
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getQuizData = (id ,callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'game/quiz/list',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.accessToken}`
        },
        data : {
            topicId: id
        },
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const editQuestionAction = (id, uploadData, callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'game/quiz/edit',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.accessToken}`
        },
        data : {
            id: id,
            uploadData: uploadData
        },
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const removeQuestionAction = (id, callback, err) => {
    var config = {
        method: 'post',
        url: SERVER_URL + 'game/quiz/remove',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json'
            // Authorization: `Bearer ${loginInfo.accessToken}`
        },
        data : {
            id: id
        },
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const addCategoryAction = (addData, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/category/create',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : addData,
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const editCategoryAction = (editData, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/category/edit',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : editData,
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const delCategoryAction = (id, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'get',
        url: SERVER_URL + 'admin/category/delete/'+id,
        crossdomain: true,
        headers: {
            'x-access-token': loginInfo.accessToken
        }
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getCategoryAction = (callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'get',
        url: SERVER_URL + 'admin/category/list',
        crossdomain: true,
        headers: {
            'x-access-token': loginInfo.accessToken
        }
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}
export const addSubCategoryAction = (addData, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/subcategory/create',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : addData,
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback() : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const editSubCategoryAction = (editData, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/subcategory/edit',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : editData,
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const delSubCategoryAction = (id, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'get',
        url: SERVER_URL + 'admin/subcategory/delete/'+id,
        crossdomain: true,
        headers: {
            'x-access-token': loginInfo.accessToken
        }
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getSubCategoryAction = (callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'get',
        url: SERVER_URL + 'admin/subcategory/list',
        crossdomain: true,
        headers: {
            'x-access-token': loginInfo.accessToken
        }
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const addNotificationAction = (addData, callback, err) => {
    api.post('/admin/notification', addData)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const editNotificationAction = (editData, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/notification/edit',
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data : editData,
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const delNotification = (id, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'get',
        url: SERVER_URL + 'admin/notification/delete/'+id,
        crossdomain: true,
        headers: {
            'x-access-token': loginInfo.accessToken
        }
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getNotificationList = (callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'get',
        url: SERVER_URL + 'admin/notification/list',
        crossdomain: true,
        headers: {
            'x-access-token': loginInfo.accessToken
        }
    };
    
    axios(config)
    .then(function (res) {
        console.log(res);
        res.data.message === 'success' ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const addDepartmentAction = (addData, callback, err) => {
    api.post('admin/department/create', addData)
    .then(function (res) {
        console.log(res);
        isStatusSuccess(res.status) ? callback(res.data) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const editDepartmentAction = ({id, ...body}, callback, err) => {
    api.put(`admin/department/${id}`, body).then(function (res) {
        isStatusSuccess(res.status) ? callback(res.data) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const delDepartmentAction = (id, callback, err) => {
    api.delete(`admin/department/${id}`).then(function (res) {
        console.log(res);
        isStatusSuccess(res.status) ? callback(res.data) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const getDepartmentsAction = (callback, err) => {
    api.post('admin/department/list')
    .then(function (res) {
        console.log(res);
        res.status === 200 ? callback(res.data.list) : err(res.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const exportExcelData = (data, callback, err) => {
    let loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    var config = {
        method: 'post',
        url: SERVER_URL + 'admin/statisticDownload',
        responseType: 'blob', // important
        crossdomain: true,
        headers: { 
            'Content-Type': 'application/json',
            'x-access-token': loginInfo.accessToken
        },
        data: data
    };
    
    axios(config)
    .then(function (res) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `export.xlsx`);
        document.body.appendChild(link);
        link.click();
        callback();
    })
    .catch(function (error) {
        console.log(error);
        err();
    });
}

export const getGameData = (data, callback, err) => {
    api.post("/admin/game/get", data)
    .then(function (res) {
        res.status === 200 ? callback(res.data.game) : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}

export const updateGameAction = (data, callback, err) => {
    api.put('/admin/competition/update', data)
    .then(function (res) {
        res.status === 200 ? callback(res.data.competition) : err();
    })
    .catch(function (error) {
        console.log(error);
    });
}