import { Button, Input, Typography, Collapse, Steps, Table, Form, Col, Row, Upload, Select, Popover, Spin } from 'antd';
import React, { useState, useEffect } from 'react';

import { formatAMPM } from '../../function';

const { Text, Title } = Typography;

export default function TutorialPage() {
  const [date, setDate] = useState('');
  const [time, setLocalTime] = useState('');
  const [username, setUserName] = useState('');
  const [mountState, setMountState] = useState(0);
  useEffect(() => {
    var event = new Date();
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    setDate(event.toLocaleDateString('es-ES', options));
    setLocalTime(formatAMPM(event));
    setMountState(1);
    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    setUserName(loginInfo.firstName +' '+ loginInfo.lastName);
  })
  return (
    <div>
      <div className='dashboard'>
          <div className='panel borderL flex-column h-center gap-bit b-shadow'>
              <div className='v-between  w-100 h-end'>
                  <Title className='large-title no-margin'>Hola {username}</Title>
                  <Text className='smail-title'>{date}</Text>
              </div>
              <div className='v-between  w-100'>
                  <Text className='bit-title'>Bienvenido a la creacion de juegos.</Text>
                  <Text className='bit-title'>{time}</Text>
              </div>
          </div>
          <div className='panel borderL mt-medium b-shadow'>
              <div className='v-center mt-medium'>
                  <Title className='large-title no-margin bold color-b'>Video Tutoriales</Title>
              </div>
              <div className='mt-bit'>
                <Row >
                  <Col span={12} style={{ padding: "30px"}}>
                    <div className='tutorial-card'>
                      <div className='flex h-center'>
                        <div className='dot'></div>
                        <Text className='ml-bit medium-title bold'>Sobre Beewise</Text>
                      </div>
                      <div className='mt-bit'>
                        <Text className='medium-title'>Revisa en que consiste Beewise y como puedes aprender jugando.</Text>
                      </div>
                      <a href='#' 
                        className='mt-bit v-center'
                        style={{
                          textDecoration: "underline",
                          fontSize: "16px",
                        }}
                      >
                        https://www.youtube.com/watch?v=8gYH7GYhfnI
                      </a>
                    </div>
                  </Col>
                  <Col span={12} style={{padding: "30px"}}>
                    <div className='tutorial-card'>
                      <div className='flex h-center'>
                        <div className='dot'></div>
                        <Text className='ml-bit medium-title bold'>Desafío, Competencias y Juegos</Text>
                      </div>
                      <div className='mt-bit'>
                        <Text className='medium-title'>Aprende las diferencias entre estos 3 terminos y que significan cada uno.</Text>
                      </div>
                      <a href='#' 
                        className='mt-bit v-center'
                        style={{
                          textDecoration: "underline",
                          fontSize: "16px",
                        }}
                      >
                        https://www.youtube.com/watch?v=8gYH7GYhfnI
                      </a>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} style={{padding: "30px"}}>
                    <div className='tutorial-card'>
                      <div className='flex h-center'>
                        <div className='dot'></div>
                        <Text className='ml-bit medium-title bold'>Puntuación</Text>
                      </div>
                      <div className='mt-bit'>
                        <Text className='medium-title'>Si quieres diferenciar de mejor manera entre Ponderado y No Ponderado, revisa este tutorial.</Text>
                      </div>
                      <a href='#' 
                        className='mt-bit v-center'
                        style={{
                          textDecoration: "underline",
                          fontSize: "16px",
                        }}
                      >
                        https://www.youtube.com/watch?v=8gYH7GYhfnI
                      </a>
                    </div>
                  </Col>
                  <Col span={12} style={{padding: "30px"}}>
                    <div className='tutorial-card'>
                      <div className='flex h-center'>
                        <div className='dot'></div>
                        <Text className='ml-bit medium-title bold'>Competidores</Text>
                      </div>
                      <div className='mt-bit'>
                        <Text className='medium-title'>Si quieres diferenciar de mejor manera entre Ponderado y No Ponderado, revisa este tutorial.</Text>
                      </div>
                      <a href='#' 
                        className='mt-bit v-center'
                        style={{
                          textDecoration: "underline",
                          fontSize: "16px",
                        }}
                      >
                        https://www.youtube.com/watch?v=8gYH7GYhfnI
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className='v-center'>
                  <Title className='large-title no-margin bold color-b'>Términos y condiciones</Title>
              </div>
              <div className='v-center'>
                  <Text className='medium-title no-margin'>Revisa nuestra politica de términos y condiciones:</Text>
              </div>
              <div className='v-center mb-large'>
                <a href='#' 
                  className='mt-bit v-center'
                  style={{
                    textDecoration: "underline",
                    fontSize: "16px",
                  }}
                >
                  http://www.beewise.cl/secciondeterminosycondiciones.php
                </a>
              </div>
          </div>
      </div>
    </div>
  );
}
