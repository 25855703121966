import React from "react";
import { Typography, Form, Row, Col, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
const { Option } = Select;

const { Text, Link } = Typography;

const InfoModal = (props) => {
  const name = props.name;
  const surname = props.surname;
  const email = props.email;
  const setDepartment = props.setDepartment;
  const department = props.department;
  const profileType = props.profileType;
  const setName = props.setName;
  const setSurname = props.setSurname;
  const setEmail = props.setEmail;
  const setProfileType = props.setProfileType;

  const getSelected = (value) => {
    console.log("SLC =====", value);
  }

  const formLayout = 'vertical';
  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;
    
  return (
    <Form
      {...formItemLayout}
      layout={formLayout}
    >
      <Row>
        <Col className='padding-small' span={12}>
          <Form.Item label={"Nombre "}>
            <Input placeholder="Ingrese nombre" onChange={(e) => setName(e.target.value)} value={name} className='hm'/>
          </Form.Item>
        </Col>
        <Col className='padding-small' span={12}>
          <Form.Item label={"Apellido"}>
            <Input placeholder="Ingrese su apellido" onChange={(e) => setSurname(e.target.value)} value={surname} className='hm'/>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col className='padding-small' span={12}>
          <Form.Item label="Email">
            <Input placeholder="Ingrese un email" onChange={(e) => setEmail(e.target.value)} value={email} className='hm'/>
          </Form.Item>
        </Col>
        {
          props.type == 'admin' ? 
            (
              <Col className='padding-small' span={12}>
                <Form.Item label={"Tipo de perfil"}>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    onChange={(value)=>{
                      setProfileType(value);
                    }} 
                    value={profileType}
                  >
                    <Option value="1">Administrador</Option>
                    <Option value="2">Sub Administrador</Option>
                  </Select>
                  {/* <Input placeholder={"- Seleccione -"} onChange={(e) =>   setProfileType(e.target.value)} value={profileType} className='hm'/> */}
                </Form.Item>
            </Col>)
            :
            ""
        }
      </Row>
    </Form>
  );
};

export default InfoModal;
