import React, { useState, useEffect } from "react";
import { Typography } from 'antd';
import { Button , Col, Row, Table, Select, Modal, List, Spin, Input } from 'antd';
import { FiFilter } from "react-icons/fi";
import VirtualList from 'rc-virtual-list';
import { BsArrowRightCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";

import { GAME_MODAL_STEP } from "../../constant";
import { ConsoleSqlOutlined } from "@ant-design/icons";

import { getSelectedPlayers, setTeamNameAction } from '../../components/actions/Player';

const { Text, Link } = Typography;
const { Option } = Select;

const AutoSetTeamDetailComponent = (props) => {
  const next = props.next;
  const competitionId = props.competitionId;
  const handleCancelComplete = props.handleCancelComplete;
  const setDetailTab = props.setDetailTab;
  const [state, setState] = useState(0);
  const [editTeamKey, setEditTeamKey] = useState(-1);
  const [playerList, setPlayerList] = useState([]);
  const [changeTeamName, setTeamName] = useState('');
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [loading, setLoading] = useState(0);

  const editTeamName = (index) => {
    setLoading(1);
    if (editTeamKey > 0) {
      setTeamNameAction(
        {id: editTeamKey, name: changeTeamName},
        () => { setState(0); setEditTeamKey(-1); setTeamName(''); },
        () => {  }
      );
    } else {
      setEditTeamKey(index);
      setLoading(0);
    }
  }

  useEffect(() => {
    let teamTableList = teamMemberList.map((team, index)=> {
      let teammates = team.members.map((member, index) => {
        if (member)  {
          return {
            key: member.id,
            player: member.profile.firstName,
            num: index + 1,
            depart: member.department,
          }
        }
      });
      // teamData[index] = members;
      return {
        column: [
          {
            title: (
              editTeamKey != team.id ? 
                <><FiEdit style={{color: "#fff", fontSize: "20px"}} onClick={() => {editTeamName(team.id); setTeamName(team.teamName);}}/>{team.teamName}</>
                :
                <>
                  <FiEdit style={{color: "#fff", fontSize: "20px"}} onClick={() => {editTeamName(team.id)}}/>
                  <Input style={{width: "150px"}} value={changeTeamName} onChange={(e) => setTeamName(e.target.value)}/>
                </>
            ),
            dataIndex: 'team',
            key: 'team',
          },
          {
            title: 'Jugador',
            dataIndex: 'player',
            key: 'player',
          },
          {
            title: "Número",
            dataIndex: 'num',
            key: 'num',
          },
          {
            title: "Departamento",
            dataIndex: 'depart',
            key: 'depart',
          },
        ],
        data: teammates
      }
    });
    setPlayerList(teamTableList);
  }, [teamMemberList, editTeamKey, changeTeamName]);
  
  useEffect(() => {
    if (!state) {
      setState(1);
      setLoading(1);
      getSelectedPlayers(
        {competitionId: competitionId}, 
        (list) => {
          setLoading(0);
          setTeamMemberList(list);
      }, () => {setLoading(0)})
    }
  });

  return (
    <>
      {
        !loading ? 
          <>
            <div className="auto-set-panel"
              style={{
                width: '70%',
                height: "80%"
              }}
            >
              <div className="v-between">
                <Text
                  style={{
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "30px",
                    color: "#142748"
                  }}
                >
                  Equipos elegidos:  {teamMemberList.length}
                </Text>
                {/* <Button className="filter-button" style={{width: "30%"}} onClick={(e) => {showModal()}}>
                  <Text className="small-title">Filtro de Busqueda</Text>
                  <FiFilter style={{fontSize: "16px", color: "#6A67CE"}}/>
                </Button> */}
              </div>
              <div className="auto-set-table mt-small" 
                style={{
                  height: "calc(100% - 80px)",
                  overflowY: "auto"
                }}
              >
                {playerList.map(function(team, i){
                    return <Table
                      columns={team.column}
                      dataSource={team.data}
                      pagination={false}
                    />
                })}
              </div>
              
            </div>
            <div className="w-100 v-around">
              <Button className="small-button hm" onClick={() => {handleCancelComplete(); setDetailTab(1);}}>Volver</Button>
            </div>
          </>
          :
          <Spin size='small'/>
      }
      
    </>
  );
};

export default AutoSetTeamDetailComponent;
