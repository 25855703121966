import React, {useEffect, useState} from "react";
import { 
  Row, 
  Typography,
  Input,
  Col,
} from 'antd';
import { AiFillMinusSquare, AiFillPlusSquare } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { editCommonQuestion, delCommonQuestion } from '../../components/actions/Other';

const { Text, Link } = Typography;
const { TextArea } = Input;

const CommonQuizComponent = (props) => {
    const alternativeList = props.alternativeList;
    const setMountAlter = props.setMountAlter;
    const [question, setQuestion] = useState('');
    const [keyword, setKeyword] = useState('');
    const [edit, setEdit] = useState(-1);
    const [data, setData] = useState({});
    const [editId, setEditId] = useState(-1);

    const setAlterAnswer = (cnt, text) => {
        let alters = [];
        data.map((d) => {
            alters.push(d);
        })
        alters[cnt] = text;
        setData(alters);
    }

    const editData = (index) => {
        setData(alternativeList[index].answers);
        setQuestion(alternativeList[index].question);
        setEditId(alternativeList[index].id);
        setKeyword(alternativeList[index].keyword);
        setEdit(index);
    }

    const init = () => {
        setData([]);
        setQuestion("");
        setEditId(-1);
        setEdit(-1);
        setKeyword("");
    }

    const del = (id) => {
        delCommonQuestion(id,
            () => {
                setMountAlter(0);
            }
        )
    }

    const editComplete = () => {
        editCommonQuestion(
            {
                editId: editId,
                question: question,
                answers: data
            },
            () => {
                init();
                setMountAlter(0);
            }
        )
    }

    const decreaseData = () => {
        const reData = [];
        data.map((d, index) => {
            if (index != data.length-1) {
                reData.push(d);
            }
        });
        setData(reData);
    }

    useEffect(() => {
        console.log(alternativeList);
    })
    return (
        <>
        <hr/>
            {
                alternativeList.map((p, index) => {
                    return (
                        <Row className="mb-large">
                            <Col span={12}>
                                <Text className='medium-title w-100' style={{fontWeight: 600}}>Pregunta: {index+1}</Text>
                                <Input 
                                    placeholder="Ingrese su pregunta" 
                                    className='hm mt-bit'
                                    onChange={(e) => setQuestion(e.target.value)}
                                    disabled={edit == index ? false : true}
                                    defaultValue={p.question}
                                />
                                {
                                    edit == index ?
                                        <div className='mt-bit'>
                                            <Text className='medium-title w-100' style={{fontWeight: 600}}>Respuestas</Text>
                                            {
                                                data.map((a, index) => {console.log(a, "<<<<<<<<<<<<<<<");
                                                    return (
                                                        <Input 
                                                            placeholder={`Alternativa ${index + 1}`} 
                                                            className='mt-bit hm'
                                                            onChange={(e) => setAlterAnswer(index, e.target.value)}
                                                            value={a}
                                                        />
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className='mt-bit'>
                                            <Text className='medium-title w-100' style={{fontWeight: 600}}>Respuestas</Text>
                                            {
                                                p.answers.map((a, index) => {
                                                    return (
                                                    <Input 
                                                        placeholder={`Alternativa ${index}`} 
                                                        className='mt-bit hm'
                                                        disabled={true}
                                                        // onChange={(e) => setAlterAnswer(index, e.target.value)}
                                                        value={a}
                                                    />
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </Col>
                            <Col span={12} className="pl-large">
                                <Text className='medium-title w-100' style={{fontWeight: 600}}>Define tu pregunta en 1 o 2 palabras:</Text>
                                <Input 
                                    placeholder={`Ingresa la(s) palabras`} 
                                    className='mt-bit hm'
                                    onChange={(e) => setKeyword(index, e.target.value)}
                                    disabled={edit == index ? false : true}
                                    defaultValue={p.keyword}
                                />
                                <div className='v-center mt-small'>
                                    {
                                        edit == index ?
                                            <AiOutlineCheckCircle 
                                                onClick={() => editComplete()}
                                                style={{color: "#38DF2A",fontSize: '20px'}} 
                                            />
                                            :
                                            <FiEdit 
                                                style={{color: "#8146ff", fontSize: "20px"}} 
                                                onClick={() => editData(index)}
                                            />
                                    }
                                    <RiDeleteBin6Line 
                                        className="ml-large"
                                        style={{color: "#EB5757", fontSize: "20px"}} 
                                        onClick={() => del(p.id)}
                                    />
                                </div>
                            </Col>
                        </Row>  
                    )
                })
            }
        </>
    );
};

export default CommonQuizComponent;
