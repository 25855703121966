import React, { useState, useEffect } from "react";
import { Typography } from 'antd';
import { Button , Col, Row, Table, Select, Modal, List, Checkbox, Input } from 'antd';
import { FiFilter } from "react-icons/fi";
import VirtualList from 'rc-virtual-list';
import { BsArrowRightCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";

import { GAME_MODAL_STEP } from "../../constant";
import { ConsoleSqlOutlined } from "@ant-design/icons";

import { getDepartmentAction } from '../../components/actions/Player';

const { Text, Link } = Typography;
const { Option } = Select;

const AutoSetTeamComponent = (props) => {
  const { teams } = props;
  const next = props.next;
  const previous = props.previous;
  const teamCnt = props.teamCnt;
  const setTeams = props.setTeams;
  const teamNames = props.teamNames;
  const setTeamNames = props.setTeamNames;
  const [state, setState] = useState(0);
  const selectedPlayer = props.selectedPlayer;
  const [dividedPlayer, setDividedPlayer] = useState(selectedPlayer);
  const [teamTableList, setTeamTable] = useState([]);  
  const [departmentList, setDepartmentList] = useState([]);
  const [checked, setChecked] = useState([]);
  const [editTeamKey, setEditTeamKey] = useState(-1);
  
  const [open, setOpen] = useState(false);
  const showModal = (data) => {
    setOpen(true);
    console.log(data);
  };
  const handleOk = (e) => {
    setOpen(false);
  };
  
  const handleCancel = (e) => {
    setOpen(false);
  };

  const onScroll = (e) => {
    console.log();
  };

  const searchBy = () => {
    let players = [];
    if (checked.length == 0 || checked.includes("Otro")) {
      players = selectedPlayer;
    } else {
      selectedPlayer.map((p) => {
        if (checked.includes(p.department)) {
          players.push(p);
        }
      });
    }
    setDividedPlayer(players);
    handleCancel();
  }

  useEffect(() => {
    console.log('===teams:;::;;', teams);
    const teamNames = teams.map((_, i) => `Equipo ${i+1}`);
    console.log('==teamsName:', teamNames);
    setTeamNames(teamNames);
  }, [teams]);
  useEffect(() => {
    setDivideTeam();
  }, [dividedPlayer, editTeamKey, teamNames]);

  const editTeamName = (index) => {
    setEditTeamKey(index);
    // setDivideTeam();
  }

  const saveTeamKey = () => {
    setEditTeamKey(-1);
  }

  const changeTeamName = (n, name) => {
    let datas = [];
    for (let i in teamNames) {
      datas.push(teamNames[i]);
    }
    datas[n] = name;
    console.log(n, name, datas)
    setTeamNames(datas);
  }

  const setDivideTeam = () => {
    let teamArr = [];
    let playerCas = 0;
    if (teamCnt != 0) {
      for (let loop = 0; loop < (Math.ceil(dividedPlayer.length / teamCnt)); loop++){
        for (let i = 0; i < teamCnt; i++) {
          if (!teamArr[i]) { teamArr[i] = []; }
          if (dividedPlayer[playerCas]) {
            teamArr[i].push(dividedPlayer[playerCas]);
            playerCas++;
          }
        }
      };
    }
    if (teamArr.length != 0) {
      let teamData = [];
      let teamTableList = teamArr.map((team, index)=> {
        let members = [];
        let teammates = team.map((member, index) => {
          members.push(member.key);
          return {
            key: member.key,
            player: member.firstName,
            num: index + 1,
            depart: member.department,
          }
        });
        teamData[index] = members;
        return {
          column: [
            {
              title: (
                editTeamKey != index ? 
                  <><FiEdit style={{color: "#fff", fontSize: "20px"}} onClick={() => {editTeamName(index)}}/>{teamNames[index] ? teamNames[index] : `Equipo ${index + 1}`}</>
                  :
                  <>
                    <FiEdit style={{color: "#fff", fontSize: "20px"}} onClick={() => {saveTeamKey()}}/>
                    <Input style={{width: "150px"}} value={teamNames[index]} onChange={(e) => changeTeamName(index, e.target.value)}/>
                  </>
              ),
              dataIndex: 'team',
              key: 'team',
            },
            {
              title: 'Jugador',
              dataIndex: 'player',
              key: 'player',
            },
            {
              title: "Número",
              dataIndex: 'num',
              key: 'num',
            },
            {
              title: "Departamento",
              dataIndex: 'depart',
              key: 'depart',
            },
          ],
          data: teammates
        }
      });
      setTeamTable(teamTableList);
      setTeams(teamArr);
      setState(1);
    }
  }
  
  useEffect(() => {
    if (!state) {
      getDepartmentAction((list) => {
        let departs = []
        list.map((l) => {
          departs.push(l.department);
        })
        departs.push('Otro')
        setDepartmentList(departs);
      })

      setDivideTeam();
      return;
    }
  });

  return (
    <>
      <div className="auto-set-panel"
        style={{
          width: '70%',
          height: "80%"
        }}
      >
        <div className="v-between">
          <Text
            style={{
              fontFamily: 'Poppins',
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "30px",
              color: "#142748"
            }}
          >
            Equipos elegidos:  {teamCnt}
          </Text>
          {/* <Button className="filter-button" style={{width: "30%"}} onClick={(e) => {showModal()}}>
            <Text className="small-title">Filtro de Busqueda</Text>
            <FiFilter style={{fontSize: "16px", color: "#6A67CE"}}/>
          </Button> */}
        </div>
        <div className="auto-set-table mt-small" 
          style={{
            height: "calc(100% - 80px)",
            overflowY: "auto"
          }}
        >
          {teamTableList.map(function(team, i){
              return <Table
                columns={team.column}
                dataSource={team.data}
                pagination={false}
              />
          })}
        </div>
        
      </div>
      <div className="w-100 v-around">
        <Button className="small-button hm" onClick={() => next(GAME_MODAL_STEP.CHOOSE_TEAM)}>Volver</Button>
        <Button className="small-button hm" onClick={() => next(GAME_MODAL_STEP.CHOOSE_DATE)}>Continuar</Button>
      </div>
      <Modal
          title=''
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
          footer=''
      >
          <div className="v-center padding-medium" >
            <div className="flex-column h-center" style={{width: "100%"}}>
              <Row className="w-100 v-between">
                <Col span={11}>
                  <List>
                    <VirtualList
                      data={[
                        1
                      ]}
                      height={300}
                      itemHeight={47}
                      itemKey="key"
                      onScroll={onScroll}
                    >
                      {(item) => (
                        <List.Item key="">
                          <List.Item.Meta
                            title={
                              <div className="v-between h-center">
                                Departamento
                                <BsArrowRightCircle style={{color: "#5E5BC0"}}/>
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    </VirtualList>
                  </List>
                </Col>
                <Col span={11}>
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    value={checked}
                    onChange={(checkedValues) => {
                      setChecked(checkedValues);
                    }}
                  >
                    <List>
                      <VirtualList
                        data={departmentList}
                        height={300}
                        itemHeight={47}
                        itemKey="key"
                        onScroll={onScroll}
                      >
                        {(item, index) => (
                          <List.Item key="">
                            <List.Item.Meta
                              title={
                                <div className="v-between h-center">
                                  {item}
                                  <Checkbox value={item} />
                                </div>
                              }
                            />
                          </List.Item>
                        )}
                      </VirtualList>
                    </List>
                  </Checkbox.Group>
                </Col>
              </Row>
              <div className='mt-medium' style={{display: "flex"}}>
                <Button className='bit-button hs' type="primary" onClick={() => handleCancel()}>Volver</Button>
                <Button 
                  className={`bit-button hs ml-medium`} 
                  onClick={() => searchBy()} 
                  type="primary"
                  // disabled={checked.length > 0 ? false : true}
                >Continuar</Button>
              </div>
            </div>
          </div>
      </Modal>
    </>
  );
};

export default AutoSetTeamComponent;
