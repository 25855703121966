import { Button, Input, Typography, Collapse, Steps, Table, Form, Col, Row, Upload, Select, Popover, Spin } from 'antd';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ExcelUpload from '../../components/general/ExcelUpload';
import Manual from '../../components/general/Manual';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import {getQuestionList} from '../../components/actions/Questions';
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineCheckCircle, AiFillInfoCircle } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { UploadOutlined } from '@ant-design/icons';

import { formatAMPM, formartTime } from '../../function';
import {addQuestionAction, editQuestionAction} from '../../components/actions/Questions';
import {
  getSubCategoryAction,
  getCategoryAction
} from '../../components/actions/Game';

import '../../scss/dashboard.scss';
import { upload } from '@testing-library/user-event/dist/upload';
import {SERVER_URL} from '../../constant';
import { getToken } from '../../utils/storageUtils';

const { Text, Title, Link } = Typography;
const { Search, TextArea } = Input;
const { Panel } = Collapse;
const { Step } = Steps;
const { Option } = Select;

const customDot = (dot, { status, index }) => (
  <img src={`/img/icon/step-${status}.png`}/>
);


export default function QuestionPage() {
  const [panel, setPanel] = useState(1);
  const [tab, setTab] = useState(1);
  const navigate = useNavigate();
  const [topicTitle, setTopicTitle] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [mountState, setMountState] = useState(0);
  const [date, setDate] = useState('');
  const [time, setLocalTime] = useState('');
  const [username, setUserName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [questionData, setQuestionData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [wholeTime, setWholeTime] = useState('');
  const [addLoading, setAddLoading] = useState(0);
    
  const [editId, setEditId] = useState(-1);
  const [editQuestionTitle, setEditQestionTitle] = useState('');
  const [editCategory, setEditCategory] = useState('');
  const [editSubCategory, setEditSubCategory] = useState('');
  const [editDuration, setEditDuration] = useState(0);
  const [editFirstAnswer, setEditFirstAnswer] = useState('');
  const [editSecondAnswer, setEditSecondAnswer] = useState('');
  const [editThirdAnswer, setEditThirdAnswer] = useState('');
  const [editFourthAnswer, setEditFourthAnswer] = useState('');

  const [categories, setCategories] = useState('');
  const [subCategories, setSubCategories] = useState('');

  const [editAlternatives, setEditAlternatives] = useState([]);

  let question = '';
  const setQuestionTitle = (val) => {
    question = val;
    console.log(question);
  }

  const loginInfo = [];

  useLayoutEffect(() =>{
    let totalTime = 0;
    let tdata = questionData.map((item, index) => {
      totalTime += parseInt(item.duration);
      return {
        key: index,
        question: item.question,
        answers: item.answers,
        category: item.category,
        time: item.duration,
      };
    });console.log(tdata);
    setTableData(tdata);
    setWholeTime(totalTime);
    var event = new Date();
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    setDate(event.toLocaleDateString('es-ES', options));
    setLocalTime(formatAMPM(event));
    setMountState(1);

    getCategoryAction((list) => {
      let categoryList = []
      let firstCategory = ''
      list.map((l, index) => {
        categoryList.push(l.category);
        if (!firstCategory) firstCategory = l.category
      });
      setCategories(categoryList);
      setCategory(firstCategory);
    });
    getSubCategoryAction((list) => {
      let subCategoryList = []
      let firstSubCategory = ''
      list.map((l, index) => {
        subCategoryList.push(l.subCategory);
        if (!firstSubCategory) firstSubCategory = l.subCategory
      });
      setSubCategories(subCategoryList);
      setSubCategory(firstSubCategory);
    });

    const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
    setUserName(loginInfo.firstName);
    setCompanyId(loginInfo.companyId)
  }, [questionData]);

  const removeQuiz = (key) => {console.log(key);
    let quizData = [];
    questionData.map((q) => {
      if (q.key !== key) quizData.push(q);
    });

    setQuestionData(quizData);
  }

  
  const questionTableColumn = [
    {
      title: 'Preguntas',
      dataIndex: 'question',
      key: 'question',
      render: (quiz, record) => (
        editId === record.key ?
          <TextArea rows={4} className='hm' value={editQuestionTitle} onChange={(e) => setEditQestionTitle(e.target.value)}/>
          :
          quiz
      )
    },
    {
      title: 'Alternativas',
      dataIndex: 'answers',
      key: 'answers',
      render: (alternative, record) => (
        editId !== record.key ?
          (<div className='flex-column h-start'>
            {alternative.map((alter, index) => (  
              <div className='h-center mt-bit'>
                {alter.isCorrect ? <CheckCircleFilled style={{color: "#01cf96",fontSize: '20px'}} /> : <CloseCircleFilled style={{color: "#f42b35",fontSize: '20px'}} />}
                <Text className='small-title ml-bit'>{alter.value}</Text>
              </div>
            ))}  
          </div>)
          : 
          (
            (<div className='flex-column h-start'>
            {alternative.map((alter, index) => (  
              <div className='h-center mt-bit'>
                {alter.isCorrect ? <CheckCircleFilled style={{color: "#01cf96",fontSize: '20px'}} /> : <CloseCircleFilled style={{color: "#f42b35",fontSize: '20px'}} />}
                {
                  <Input className='hm' value={editAlternatives[index].value} onChange={(e) => {
                    const newEditAlternative = [...editAlternatives];
                    newEditAlternative[index].value = e.target.value;
                    setEditAlternatives(newEditAlternative);
                  }} />
                }
              </div>
            ))}  
          </div>)
          )
      )
    },
    {
      title: (<>Categoría/<br/>subcategoría</>),
      dataIndex: 'category',
      key: 'category',
      render: (category, record) => (
        editId === record.key ?
          <>
            <Input
              style={{
                width: "100%",
              }}
              onChange={((e) => setEditCategory(e.target.value))} 
              value={editCategory}
            />
          </>
          // <Input className='hm' value={editCategory} onChange={(e) => setEditCategory(e.target.value)}/>
          :
          <>{category}<br/>{record.subCategory}</>
      )
    },
    {
      title: 'Tiempo',
      dataIndex: 'time',
      key: 'time',
      render: (time, record) => (
        editId === record.key ?
        <Select
          defaultValue="select"
          style={{
            width: "100%",
          }}
          onChange={((val) => setEditDuration(val))} 
          value={editDuration}
        >
          {/* {
            categories.map((item) => {
              return <Option value={item}>{item}</Option>
            })
          } */}
          <Option value={10}>10</Option>
          <Option value={20}>20</Option>
          <Option value={30}>30</Option>
          <Option value={40}>40</Option>
          <Option value={50}>50</Option>
        </Select>
          // <Input className='hm' value={editDuration} onChange={(e) => setEditDuration(e.target.value)}/>
          :
          time + " seg."
      )
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      render: (i, record) => (
        editId !== record.key ?
          <div className='v-around'>
            <BiEdit style={{color: "#8146FF",fontSize: '20px'}} onClick={() => editItem(i, record.key, record)}/>
            <RiDeleteBin6Line style={{color: "#F26E6E",fontSize: '20px'}} onClick={() => removeItem(record.key)}/>
          </div>
          :
          <div className='v-around h-center'>
            <AiOutlineCheckCircle style={{color: "#38DF2A",fontSize: '20px'}} onClick={() => editQuestion()} />
            <RiDeleteBin6Line style={{color: "#F26E6E",fontSize: '20px'}} onClick={() => removeItem(record.key)}/>
          </div>
      )
    },
  ];

  const editItem = (i, key, record) => {
    console.log('===record:', record);
    try {
      setEditId(key);
      setEditCategory(record.category);
      setEditDuration(record.time);
      setEditQestionTitle(record.question);
      setEditAlternatives(record.answers);
     /*  setEditFirstAnswer(record.answers[0].value);
      setEditSecondAnswer(record.answers[1].value);
      setEditThirdAnswer(record.answers[2].value);
      setEditFourthAnswer(record.answers[3].value); */

    }catch(e) {
      console.log('===CATXCH:', e);
    }
  }

  const removeItem = (key) => {
    let rebuildData = [];
    questionData.map((d, index) => {
      if (index === key) return;
      rebuildData.push(d);
    });
    setQuestionData(rebuildData);
  }

  const addQuestion = () => {
    console.log('===addQuestion:', questionData);
    setAddLoading(1);
    addQuestionAction({
      name: topicTitle,
      video: imgUrl,
      questions: questionData,
      shared:[companyId]
    }, () => {
      setAddLoading(0);
      navigate('/game/library');
    }, () => {
      setAddLoading(0);
    });
  }

  const editQuestion = () => {
    let rebuildQuestion = [];
    questionData.map((q, index) => {
      if (index === editId) {
        rebuildQuestion.push({
          question: editQuestionTitle,
          type: 1,
          category: editCategory,
          duration: editDuration,
          answers: q.answers,
        });
        return;
      }
      rebuildQuestion.push(q);
    });
    setQuestionData(rebuildQuestion);
    setEditId(-1);
  }
  
  const formLayout = 'vertical';
  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;

  const uploadProps = {
      action: SERVER_URL + 'super/game/upload-image',
      onChange({ file, fileList }) {
        if (file.status !== 'uploading') {
          console.log(file, fileList);
        }
      },
      accept: 'image/*',
      maxCount: 1,
      headers: {
        'Authorization': 'Bearer ' + getToken()
      },
    }

  const content = (
    <div className='flex-column v-center' style={{width: "230px"}}>
      <div className='w-100 v-center'>
        <AiFillInfoCircle 
          className='ml-bit' 
          style={{color: "#5E5BC0", fontSize: "50px"}} 
        />
      </div>
      <Text>Al cargar un video o una foto, ésta se mostrará al comienzo de cada competencia, por lo que las preguntas de todo el juego deben tener relación a esta foto o video que estas subiendo</Text>
    </div>
  );

  console.log('=====tableData:', tableData);

  return (
    <>
      {
        panel === 1 ? 
          (
            <div>
              <div className='dashboard'>
              <div className='panel borderL flex-column h-center gap-bit b-shadow' style={{}}>
                  <div className='v-between  w-100 h-end'>
                      <Title className='large-title no-margin'>Hola {username},</Title>
                      <Text className='smail-title'>{date}</Text>
                  </div>
                  <div className='v-between  w-100'>
                      <Text className='bit-title'>Bienvenido a la creacion de juegos.</Text>
                      <Text className='bit-title'>{time}</Text>
                  </div>
              </div>
                  <div className='panel borderL mt-medium b-shadow'>
                      <div className='v-between'>
                          <Title className='large-title no-margin bold'>Datos Generales</Title>
                          <div className='h-center' style={{width: "40%"}}>
                            <Steps current={0} progressDot={customDot}>
                              <Step />
                              <Step />
                              <Step />
                            </Steps>
                          </div>
                      </div>
                      <Form
                        {...formItemLayout}
                        layout={formLayout}
                      >
                        <div className='mt-large ml-large' style={{width: "30%"}}>
                          <Form.Item label="Nombre del juego">
                            <Input placeholder="Ingrese nombre del juego" onChange={(e) => setTopicTitle(e.target.value)} className='hm'/>
                          </Form.Item>
                        </div>
                        <div className='mt-large'>
                          <Title className='large-title no-margin bold color-b'>Multimedia</Title>
                          <Text className='bit-title bold ml-large'>Ingresa una imagen o un video complementario / NO pueden ser ambos</Text>
                        </div>
                        <div className='mt-large ml-large'>
                          <Row>
                            <Col span={10}>
                              <Form.Item label={
                                <>
                                  Subir Imagen 
                                  <Popover content={content} placement="topLeft" trigger="click">
                                    <AiFillInfoCircle 
                                      className='ml-bit' 
                                      style={{color: "#5E5BC0", fontSize: "18px"}} 
                                    />
                                  </Popover>
                                </>
                              }>
                                <Upload 
                                  {...uploadProps}
                                >
                                  <Button className={`common-button hm`} type="primary">
                                    <UploadOutlined />Selecciona un archivo
                                  </Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                            <Col span={10}>
                              <Form.Item label={
                                <>
                                  Subir Video 
                                  <Popover content={content} placement="topLeft" trigger="click">
                                    <AiFillInfoCircle 
                                      className='ml-bit' 
                                      style={{color: "#5E5BC0", fontSize: "18px"}} 
                                    />
                                  </Popover>
                                </>
                              }>
                                <Input placeholder="Ingrese URL del Video" onChange={(e) => setImgUrl(e.target.value)} className='hm'/>
                              </Form.Item>
                            </Col>
                            <Col span={4}></Col>
                          </Row>
                        </div>
                        <div className='mt-large v-end'>
                          <Form.Item>
                            <Button className={`small-button hm`} onClick={() => {}} type="primary">
                              Cancelar
                            </Button>
                          </Form.Item>
                          <Form.Item>
                            <Button className={`small-button hm ml-medium ${topicTitle?"":"back-grey"}`} disabled={topicTitle?false:true} onClick={() => setPanel(2)} type="primary">
                              Continuar
                            </Button>
                          </Form.Item>
                        </div>
                      </Form>
                  </div>
              </div>
            </div>
          )
          :
          (
            <>
              <div className='dashboard'>
                <div className='panel borderL mt-medium b-shadow'>
              {tab != 1 ? 
                  <div className='content-h flex' style={{}}>
                    <div className='w-50 h-end flex-column h-start'>
                      <Title className='large-title no-margin'>Hola {username},</Title>
                      <Text className='smail-title'>Bienvenido a la creacion de juegos.</Text>
                    </div>
                    <div className='v-end w-50'>
                      <Button className={`small-button hm`} 
                        onClick={() => addQuestion()} 
                        type="primary"
                        disabled={tableData.length && !addLoading > 0 ? false : true}
                      >
                        {
                          addLoading ? 
                            <Spin size="small"/>
                            :
                            "Guardar Juego"
                        }
                      </Button>
                    </div>
                  </div>
                :
                  <div className='content-h' style={{}}>
                    <div className='v-between  w-100 h-end'>
                        <Title className='large-title no-margin'>Hola {username},</Title>
                        <Text className='smail-title'>{date}</Text>
                    </div>
                    <div className='v-between  w-100'>
                      <Text className='bit-title'>Bienvenido a la creacion de juegos.</Text>
                      <Text className='bit-title'>{time}</Text>
                    </div>
                  </div>
              }
              </div>
              </div>
              <div className='dashboard'>
                  <div className='panel borderL b-shadow'>
                      <div className='v-between'>
                          <Title className='large-title no-margin bold'>Agrega tus preguntas</Title>
                          <div className='h-center' style={{width: "40%"}}>
                            <Steps current={1} progressDot={customDot}>
                              <Step />
                              <Step />
                              <Step />
                            </Steps>
                          </div>
                      </div>
                      <Text className='bit-title'>Para cargar tus preguntas puedes hacerlo de forma manual o carga masiva en excel.</Text>
                      <div className='v-between h-center'>
                        <div className='padding-bit back-grey pv-small ph-bit mt-medium borderS' style={{display: "table"}}>
                            <Button className={tab === 1 ? `small-button color-black-button-s back-grey hm` : `small-button color-black-button-s hm`} disabled={tab === 1 ? false : true} onClick={() => setTab(2)} type="primary">
                              Manual
                            </Button>
                            <Button className={tab !== 1 ? `small-button color-black-button-s back-grey hm` : `color-black-button-s small-button hm`} disabled={tab !== 1 ? false : true} onClick={() => setTab(1)} type="primary">
                              Cargar excel
                            </Button>
                        </div>
                        {
                          tab != 1 ?
                            <div className='mt-medium'>
                              <Button className= "small-button hm" onClick={() => {setPanel(1); setTab(1);}} type="primary">
                                Volver al inicio
                              </Button>
                            </div>
                          :
                            ""
                        }
                      </div>
                      {tab === 1 ? <ExcelUpload setTab={setTab} setQuestionData={setQuestionData} setPanel={setPanel}/> : <Manual setQuestionData={setQuestionData} questionData={questionData}/>}
                  </div>
                  {tab !== 1 ? 
                    <div className='panel borderL mt-medium b-shadow'>
                        <div className='summary-header-panel v-between pv-medium h-center'>
                          <Text className='summary-title'>Resumen de preguntas</Text>
                          <Text className='summary-text'>Cantidad de preguntas: {tableData.length}</Text>
                          <Text className='summary-text'>Total estimado de juego: {formartTime(wholeTime)}</Text>
                        </div>
                        <Table
                            columns={questionTableColumn}
                            dataSource={tableData}
                            pagination={false}
                        />
                    </div>
                    :''}
              </div>
            </>
          )
      }
    </>
  );
}
