import { 
  Button, 
  Form, 
  Input, 
  Typography, 
  Collapse, 
  Radio, 
  Col, 
  Row, 
  Space, 
  Modal, 
  Select, 
  Table,
  Spin,
  Popover,
} from 'antd';

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { AiFillMinusSquare, AiFillPlusSquare, AiOutlineCheckCircle, AiFillEye } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { BsFillShareFill } from "react-icons/bs";

import { 
  addNotificationAction, 
  editNotificationAction,
  getCompetitionList,
  getNotificationList,
  delNotification,
} from '../../components/actions/Game';
import { addCommonQuestion, getCommonQuestions } from '../../components/actions/Other';

import { formatAMPM } from '../../function';
import Department from '../../components/other/Department';
import Category from '../../components/other/Category';
import SubCategory from '../../components/other/SubCategory';
import CommonQuiz from '../../components/other/CommonQuiz';
import '../../scss/dashboard.scss';
import { addQuestionAction } from '../../components/actions/Questions';

const { Text, Title, Link } = Typography;
const { Search } = Input;
const { Panel } = Collapse;
const { Option } = Select;

export default function OtherPage() {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(1);
  const [mountState, setMountState] = useState(0);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [username, setUserName] = useState('');
  const [alterArr, setAlterArr] = useState([0,1]);

  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState(["",""]);

  const [competition, setCompetition] = useState(0);
  const [notifyTitle, setNotifyTitle] = useState('');
  const [message, setMessage] = useState('');
  const [notificationTab, setNotificationTab] = useState(1);
  const [notifyList, setNotifyList] = useState([]);
  const [notifyListState, setNotifyListState] = useState(0);
  const [competitionList, setCompetitionList] = useState([]);
  const [alternativeList, setAlternativeList] = useState([]);
  const [mountAlter, setMountAlter] = useState(0);
  const [notifyEditId, setNotifyEditId] = useState(-1);
  const [editTitle, setEditTitle] = useState('');
  const [editCompetition, setEditCompetition] = useState('');
  const [addAlterLoading, setAddAlterLoading] = useState(0);
  const [addNotifyLoading, setAddNotifyLoading] = useState(0);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (!mountState) {
      var event = new Date();
      var options = { year: 'numeric', month: 'long', day: 'numeric' };
      setDate(event.toLocaleDateString('es-ES', options));
      setTime(formatAMPM(event));
      setMountState(1);
      const loginInfo = JSON.parse(localStorage.getItem('admin-auth'));
      setUserName(loginInfo.firstName +' '+ loginInfo.lastName);
      
      getCompetitionList({}, (list) => {
        console.log("lista====",list);
        setCompetitionList(list)
      })
    }
  })

  useEffect(() => {
    if (!mountAlter) {
      setMountAlter(1);
      getAlternatives();
    }
  })

  const init = () => {
    setAnswers(["",""]);
    setQuestion("");
    setAlterArr([0,1]);
    setKeyword("");
  }

  useEffect(() => {
    if (!notifyListState) {
      setNotifyListState(1)
      getNotificationList((list) => {
        let notifiese = []
        list.map((n, index) => {
          notifiese.push({
            key: n.id,
            title: n.title,
            message: n.message,
            competition: n.competitionName,
            competitionId: n.competitionId,
            date: new Date(n.createdAt).toLocaleDateString()
          })
        });
        setNotifyList(notifiese)
      })
    }
  }, [notifyListState])

  const getAlternatives = () => {
    getCommonQuestions((list) => {
      setAlternativeList(list);
    });
  }

  const setAlterAnswer = (cnt, text) => {
    var alters = [];
    for (let i in answers) {
      if (i == cnt) {
        alters.push(text);
      } else {
        alters.push(answers[i]);
      }
    }
    if (cnt >= answers.length) {
      alters[cnt] = text;
    }
    setAnswers(alters);
  }

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setTabValue(e.target.value);
  };

  const changeNotifyTab = (e) => {
    console.log('radio checked', e.target.value);
    setNotificationTab(e.target.value);
  };

  const decreaseAlter = () => {
    let reanswers = [];
    answers.map((a, index) => {
      if (index != answers.length-1) {
        reanswers.push(a)
      }
    })
    setAnswers(reanswers)
  }

  const addAlternative = () => {
    setAddAlterLoading(1);
    addCommonQuestion({
      question: question,
      keyword: keyword,
      answers: answers
    }, (data) => {
      init();
      setMountAlter(0);
      setAddAlterLoading(0);
    }, () => {
      setAddAlterLoading(0);
    });
  }

  const setAlterItem = (n, val) => {
    let vAlterArr = alterArr;
    vAlterArr[n] = val;
    setAlterArr(vAlterArr);
  }
  
  const modalNotification = () => {
    showModal()
  }
  
  const [open, setOpen] = useState(false);
  const showModal = (data) => {
    setOpen(true);
    console.log(data);
  };
  const handleOk = (e) => {
    setOpen(false);
  };
  
  const handleCancel = (e) => {
    setOpen(false);
  };
  
  const [openConfirm, setOpenConfirm] = useState(false);
  const showModalConfirm = (data) => {
    setOpenConfirm(true);
    console.log(data);
  };
  const handleOkConfirm = (e) => {
    setOpen(false);
  };
  
  const handleCancelConfirm = (e) => {
    setOpen(false);
  };

  const addNotification = () => {
    setAddNotifyLoading(1);
    addNotificationAction({
      competition: competition,
      title: notifyTitle,
      description: message,
      users:''
    }, () => {
      setNotifyTitle('');
      setMessage('');
      setCompetition(0);
      setNotifyListState(0);
      handleCancel();
      setAddNotifyLoading(0);
    }, () => {
      setAddNotifyLoading(0);
    })
  }

  const deleteNotification = (id) => {
    delNotification(id, () => {
      setNotifyListState(0);
    })
  }

  const tootipContent = (
    <div className='flex-column v-center' style={{width: "230px"}}>
      <div className='w-100 v-center'>
        <AiFillEye 
          className='ml-bit' 
          style={{color: "#5E5BC0", fontSize: "50px"}} 
        />
      </div>
      <Text>Al cargar un video o una foto, ésta se mostrará al comienzo de cada competencia, por lo que las preguntas de todo el juego deben tener relación a esta foto o video que estas subiendo</Text>
    </div>
  );

  const notifyColumn = [
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      },
      render: (title, record) => (
        notifyEditId === record.key ?
          <Input className='hm' value={editTitle} onChange={(e) => setEditTitle(e.target.value)}/>
          :
          title
      )
    },
    {
      title: 'Compartido',
      dataIndex: 'competition',
      key: 'competition',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      },
      render: (competition, record) => (
        notifyEditId === record.key ?
          <Select
            className='hm w-100'
            defaultValue={editCompetition}
            onChange={((val) => setEditCompetition(val))} 
          >
            {
              competitionList.map((c) => {
                return <Option value={c.competition_id}>{c.competitionName}</Option>
              })
            }
            <Option value={0}>All</Option>
          </Select>
          :
          competition
      )
    },
    {
      title: 'Fecha compartido',
      dataIndex: 'date',
      key: 'date',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      }
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className='v-around h-center'>
          <Popover content={(
              <div className="v-center padding-medium" >
              <div className="flex-column h-center" style={{width: "90%"}}>
                <div className="modal-panel padding-small" style={{width: "300px"}}>
                  <div className='v-between'>
                    <Text className="medium-title mt-bit mt-medium">Beewise</Text>
                    <Text className="medium-title mt-bit mt-medium">1 min</Text>
                  </div>
                  <div className='mt-bit'>
                    <Text 
                      className="medium-title color-black"
                      style={{fontWeight: "600"}}
                    >{record.title}</Text>
                  </div>
                  <div className='mt-bit'>
                    <Text 
                      className="medium-title"
                      style={{fontWeight: "600"}}
                    >{record.message}</Text>
                  </div>
                </div>
              </div>
            </div>
          )} placement="topRight" trigger="click">
            <AiFillEye onClick={() => {}} style={{color: "#8146ff", fontSize: "20px"}}/>
          </Popover>
          {
            notifyEditId === record.key ?
              <AiOutlineCheckCircle 
                onClick={() => editNotifyComplete()}
                style={{color: "#38DF2A",fontSize: '20px'}} 
              />
              :
              <FiEdit style={{color: "#8146ff", fontSize: "20px"}} onClick={() => {editNotify(record.key)}}/>
          }
          <BsFillShareFill onClick={() => {}} style={{color: "#8146ff", fontSize: "20px"}}/>
          <RiDeleteBin6Line 
            style={{color: "#EB5757", fontSize: "20px"}} 
            onClick={() => deleteNotification(record.key)}
          />
        </div>
      )
    },
  ];

  const editNotify = (id) => {
    setNotifyEditId(id);
    notifyList.map((n) => {
      if (n.key == id) {console.log(n.competitionId)
        setEditCompetition(n.competitionId);
        setEditTitle(n.title);
      }
    });
  }

  const editNotifyComplete = () => {
    editNotificationAction({
      competitionId: editCompetition,
      title: editTitle,
      id: notifyEditId
    }, () => {
      setNotifyEditId(-1);
      setEditCompetition(0);
      setEditTitle('');
      setNotifyListState(0);
    }, () => {
    })
  }
  
  return (
    <div className='dashboard'>
        <div className='panel borderL flex-column h-center gap-bit b-shadow'>
            <div className='v-between  w-100 h-end'>
                <Title className='large-title no-margin'>Hola {username}</Title>
                <Text className='smail-title'>{date}</Text>
            </div>
            <div className='v-between  w-100'>
                <Text className='bit-title'>Configura</Text>
                <Text className='bit-title'>{time}</Text>
            </div>
        </div>
        <div className='mt-large'>
          <Collapse 
              bordered={false}
              defaultActiveKey={[]}
          >
              <Panel 
                  header="Agregar preguntas" key="1" 
                  className='collapse-panel mb-large'
              >
                <div className='pv-large'>
                  <Text className='medium-title color-black'>
                    Desde aquí podrás añadir preguntas para conocer aún más a los jugadores, esto te podrá permitir filtrar de mejor manera a los participantes en cada juego. 
                  </Text>
                </div>
                <div className='pv-large'>
                  <div className='pv-medium' style={{marginTop: "40px"}}>
                    <Row className='mt-bit'>
                        <Col span={12}>
                          <Text className='medium-title'>
                            {
                              tabValue == 1 ?
                                "El jugador visualizará la pregunta en su registro de la App."   
                                :
                                "El jugador visualizará la pregunta cuando complete su registro"
                            }
                          </Text>
                          <Input 
                            placeholder="Ingrese su pregunta" 
                            className='hm mt-bit'
                            onChange={(e) => setQuestion(e.target.value)}
                            disabled={alternativeList.length >= 3 ? true : false}
                            value={question}
                          />
                          <div className='mt-bit'>
                            {
                              answers.map((a, index) => {
                                return (
                                  <Input 
                                    placeholder={`Alternativa ${index + 1}`} 
                                    className='mt-bit hm'
                                    onChange={(e) => setAlterAnswer(index, e.target.value)}
                                    disabled={alternativeList.length >= 3 ? true : false}
                                    value={a}
                                  />
                                )
                              })
                            }
                          </div>
                        </Col>
                      <Col span={12} className="pl-large">
                        <Text className='medium-title' >Define tu pregunta en 1 o 2 palabras:</Text>
                        <Input 
                          placeholder={`Ingresa la(s) palabras`} 
                          className='mt-bit hm'
                          onChange={(e) => setKeyword(e.target.value)}
                          disabled={alternativeList.length >= 3 ? true : false}
                          value={keyword}
                        />
                        <div className='mt-medium'>
                          <div 
                            className='h-center'
                            onClick={() => {
                              decreaseAlter()
                            }}
                          >
                            <AiFillMinusSquare className='color-b' style={{fontSize: "18px"}} />
                            <Text className='small-title color-b ml-bit'>Eliminar una Alternativa</Text>
                          </div>
                          <div 
                            className='h-center mt-small' 
                            onClick={() => 
                              // setAlterArr([
                              //   ...alterArr,
                              //   ""
                              // ])
                              setAlterAnswer(answers.length, "")
                            }
                          >
                            <AiFillPlusSquare className='color-b' style={{fontSize: "18px"}} />
                            <Text className='small-title color-b ml-bit'>Agregar  otra Alternativa</Text>
                          </div>
                        </div>
                        <div className='v-start'>
                          <Button 
                            className={`bit-button hs mt-small ${question == "" || addAlterLoading || !keyword ? "back-grey" : ""}`} 
                            type="primary"
                            onClick={() => addAlternative()}
                            disabled={question == "" || addAlterLoading || !keyword ? true : false}
                          >
                            {
                              !addAlterLoading ?
                                "Agregar pregunta"
                                :
                                <Spin size="small"/>
                            }
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <div style={{marginTop: "40px"}}>
                      <Text className='medium-title' style={{fontWeight: 500}}>
                        Resumen Preguntas
                      </Text>
                      <CommonQuiz alternativeList={alternativeList} setMountAlter={setMountAlter}/>
                    </div>
                  </div>
                </div>
              </Panel>
              <Panel header="Notificaciones Push" key="2" 
                  className='collapse-panel mb-large'
              >
                <div className='pv-large'>
                  <Text className='medium-title color-black'>
                    Personaliza y envía notificaciones a los jugadores.
                  </Text>
                </div>
                <div className='pv-large mt-medium'>
                  <Row className='mt-bit'>
                    <Col span={12}>
                      <div>
                        <div className='v-between'>
                          <Text className='medium-title color-black' style={{fontWeight: 600}}>Título</Text>
                          <Text className='medium-title'>{notifyTitle.length}/50</Text>
                        </div>
                        <Input 
                          placeholder="Ingrese un título" 
                          onChange={(e) => setNotifyTitle(e.target.value)}
                          value={notifyTitle}
                          className='mt-bit hm'
                        />
                      </div>
                      <div className='mt-small'>
                        <div className='v-between'>
                          <Text className='medium-title color-black' style={{fontWeight: 600}}>Mensaje</Text>
                          <Text className='medium-title'>{message.length}/70</Text>
                        </div>
                        <Input 
                          placeholder="Ingrese un mensaje"
                          onChange={(e) => setMessage(e.target.value)}
                          value={message} 
                          className='mt-bit hm'
                        />
                      </div>
                    </Col>
                    <Col span={12} className="pl-large">
                    <Text className='medium-title color-black' style={{fontWeight: 600}}>Destinatarios</Text>
                      <Radio.Group className='mt-small w-100' onChange={changeNotifyTab} value={notificationTab}>
                        <Space direction="vertical">
                          <Radio value={1}>Todos</Radio>
                          <Radio value={2}>Por Competencia</Radio>
                        </Space>
                      </Radio.Group>
                      {
                        notificationTab == 2 ? 
                          (
                            <div style={{ marginTop: "19px" }}>
                              <Select
                                className='mt-bit hm w-100'
                                onChange={((val) => setCompetition(val))} 
                              >
                                {
                                  competitionList.map((c) => {
                                    return <Option value={c._id}>{c.name}</Option>
                                  })
                                }
                              </Select>
                            </div>
                          )
                          : 
                          ""
                      }
                    </Col>
                  </Row>
                  <div className='v-center mt-medium'>
                    <Button 
                      className={`small-button hs mt-small ${notifyTitle.length == 0 || message.length == 0 ? 'back-grey' : ''}`} 
                      onClick={() => modalNotification()} 
                      type="primary"
                      disabled={notifyTitle.length == 0 || message.length == 0 ? true : false}
                    >
                      Publicar
                    </Button>
                  </div>
                  <div style={{marginTop: "40px", display:"none"}}>
                    <Text className='medium-title' style={{fontWeight: 500}}>
                      Resumen Notificaciones
                    </Text>
                    <Table
                      columns={notifyColumn}
                      dataSource={notifyList}
                      pagination={false}
                    />
                  </div>
                </div>
              </Panel>
              <Panel header="Crear Departamentos" key="3" 
                  className='collapse-panel mb-large'
              >
                <Department />
              </Panel>
              <Panel header="Crear Categorías" key="4" 
                  className='collapse-panel mb-large d-none'
              >
                <Category />
              </Panel>
              <Panel header="Crear SubCategorías" key="5" 
                  className='collapse-panel mb-large d-none'
              >
                <SubCategory />
              </Panel>
          </Collapse>
        </div>
        <Modal
            title=''
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            footer=''
        >
            <div className="v-center padding-medium" >
              <div className="flex-column h-center" style={{width: "90%"}}>
                <Text className="large-title color-b mt-medium">¿Publicar notificación?</Text>
                <div className='w-100 mt-bit'>
                  <Text className="medium-title mt-bit mt-medium">Así se visualizará tu notificación</Text>
                </div>
                <div className="modal-panel padding-small" style={{width: "100%"}}>
                  <div className='v-between'>
                    <Text className="medium-title mt-bit mt-medium">Beewise</Text>
                    <Text className="medium-title mt-bit mt-medium">1 min</Text>
                  </div>
                  <div className='mt-bit'>
                    <Text 
                      className="medium-title color-black"
                      style={{fontWeight: "600"}}
                    >{notifyTitle}</Text>
                  </div>
                  <div className='mt-bit'>
                    <Text 
                      className="medium-title"
                      style={{fontWeight: "600"}}
                    >{message}</Text>
                  </div>
                </div>
                <div className='mt-medium' style={{display: "flex"}}>
                  <Button className='bit-button hs' type="primary" onClick={() => handleCancel()}>Volver</Button>
                  <Button 
                    className='bit-button hs ml-medium' 
                    onClick={() => addNotification()} 
                    type="primary"
                    disabled={!addNotifyLoading ? false : true}
                  >
                    {
                      !addNotifyLoading ?
                        "Publicar"
                        :
                        <Spin size='small'/>
                    }
                  </Button>
                </div>
              </div>
            </div>
        </Modal>
        <Modal
            title=''
            open={openConfirm}
            onOk={handleOkConfirm}
            onCancel={handleCancelConfirm}
            footer=''
        >
            <div className="v-center padding-medium" >
              <div className="flex-column h-center" style={{width: "70%"}}>
                <Text className="large-title color-b mt-medium">¿Publicar notificación?</Text>
                <Text className="medium-title mt-bit mt-medium">Así se visualizará tu notificación</Text>
                <div className="modal-panel pv-small">
                  <div className='v-between'>
                    <Text className="medium-title mt-bit mt-medium">Beewise</Text>
                    <Text className="medium-title mt-bit mt-medium">1 min</Text>
                  </div>
                  <Text className="medium-title mt-bit mt-medium">{notifyTitle}</Text>
                </div>
                <div className='mt-medium' style={{display: "flex"}}>
                  <Button className='bit-button hs' type="primary" onClick={() => handleCancelConfirm()}>Aceptar</Button>
                </div>
              </div>
            </div>
        </Modal>
    </div>
  );
}
