import React, {useEffect, useState} from "react";
import { 
  Table, 
  Typography,
  Input,
  Button,
  Spin,
  Modal,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

import { 
  addSubCategoryAction, 
  getSubCategoryAction,
  delSubCategoryAction,
  editSubCategoryAction,
} from '../actions/Game';

const { Text, Link } = Typography;
const { TextArea } = Input;

const SubCategoryComponent = (props) => {
  const [listState, setListState] = useState(0);
  const [subCategoriese, setSubCategriese] = useState([]);
  const [subCategoryTitle, setSubCategoryTitle] = useState('');

  const [editId, setEditId] = useState(-1);
  const [editSubCategory, setEditSubCategory] = useState('');
  const [addLoading, setAddLoading] = useState(0);

  const [delId, setDelId] = useState(-1);
  const [deleteLoading, setDeleteLoading] = useState(0);

  useEffect(() => {
    if (!listState) {
      setListState(1);
      getSubCategoryAction((list) => {
        let subCategoryList = []
        list.map((l, index) => {
          subCategoryList.push({
            key: l.id,
            subCategory: l.subCategory
          });
        });
        setSubCategriese(subCategoryList);
      });
    }
  })
  const init = () => {
  }

  const deleteSubCategory = () => {
    delSubCategoryAction(delId, () => {
      setListState(0)
      setDelId(-1);
      setListState(0);
      handleCancelDelete();
    }, () => {
      setDeleteLoading(0);
      setDelId(-1);
      handleCancelDelete();
    })
  }

  const edit = (id, subCategory) => {
    setEditId(id);
    setEditSubCategory(subCategory);
  }

  const editComplete = () => {
    editSubCategoryAction(
      {id: editId, subCategory: editSubCategory}, 
      () => {
        setEditId(-1);
        setListState(0);
      }
    )
  }

  const subCategoryColumn = [
    {
      title: 'SubCategorías',
      dataIndex: 'subCategory',
      key: 'subCategory',
      sorter: {
        compare: (a, b) => a.correct - b.correct,
        multiple: 3,
      },
      render: (subCategory, record) => (
        editId === record.key ?
          <Input className='hm' value={editSubCategory} onChange={(e) => setEditSubCategory(e.target.value)}/>
          :
          subCategory
      )
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className='v-around h-center'>
          {
            editId === record.key ?
              <AiOutlineCheckCircle 
                onClick={() => editComplete()}
                style={{color: "#38DF2A",fontSize: '20px'}} 
              />
              :
              <FiEdit 
                style={{color: "#8146ff", fontSize: "20px"}} 
                onClick={() => edit(record.key, record.subCategory)}
              />
          }
          <RiDeleteBin6Line 
            style={{color: "#EB5757", fontSize: "20px"}} 
            onClick={() => deleteModal(record.key)}
          />
        </div>
      )
    },
  ];

  const deleteModal = (key) => {
      setDelId(key);
      showModalDelete();
  }
  
  const [openDelete, setOpenDelete] = useState(false);
  const showModalDelete = () => {
      setOpenDelete(true);
  };
  const handleOkDelete = (e) => {
      console.log(e);
      setOpenDelete(false);
  };
  
  const handleCancelDelete = (e) => {
      console.log(e);
      setOpenDelete(false);
  };

  const addSubCategory = () => {
    setAddLoading(1);
    addSubCategoryAction(
      {subCategory: subCategoryTitle},
      () => {
        setListState(0)
        setSubCategoryTitle('')
        setAddLoading(0);
      },
      () => {setAddLoading(0)}
    );
  }
  return (
    <>
    <div className='pv-large'>
      <Text className='medium-title color-black'>
      Ingresa las Categorías acorde a los temas que se van a aprender, recuerda que estos datos te ayudarán a complementar tus juegos y optimizar la visualización de los resultados.
      </Text>
    </div>
    <div className='pv-large mt-medium flex-column h-center'>
      <div className='v-around mt-medium' style={{width: "70%"}}>
        <Input 
          placeholder="Ingresa una nueva SubCategoría"
          onChange={(e) => setSubCategoryTitle(e.target.value)}
          className='mt-bit hs'
          value={subCategoryTitle}
          style={{width: "50%"}}
        />
        <Button 
          className={`bit-button hs mt-bit ${subCategoryTitle?"":"back-grey"}`}
          disabled={subCategoryTitle && !addLoading?false:true}
          onClick={() => addSubCategory()} 
          type="primary"
        >
          {
            !addLoading ?
              "Añadir SubCategoría"
              :
              <Spin size='small'/>
          }
        </Button>
      </div>
      <div style={{marginTop: "40px", width: "70%"}}>
        <Table
          columns={subCategoryColumn}
          dataSource={subCategoriese}
          pagination={false}
        />
      </div>
    </div>
        
    <Modal
        title=''
        open={openDelete}
        onOk={handleOkDelete}
        onCancel={handleCancelDelete}
        footer=''
    >
        <div className='modal-content' style={{border: "none"}}>
            
            <img src='/img/icon/warning.png' width="91"/>
            <h3>Eliminar  SubCategoría</h3>
            <Text className='medium-title text-center'>¿Estás seguro de que quieres  eliminar la SubCategoría seleccionada?</Text>
            <div>
              <Button className='bit-button hs' onClick={() => handleCancelDelete()} type="primary">Volver</Button>
              <Button className='bit-button hs ml-medium' onClick={() => deleteSubCategory()} type="primary">
                {
                  deleteLoading == 1 ?
                    <Spin size="small"/>
                    :
                    "Eliminar"
                }
              </Button>
            </div>
        </div>
    </Modal>
    </>
  );
};

export default SubCategoryComponent;
